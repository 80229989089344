<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <!-- Card Container -->
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0">
        <!--begin:designs-->
        <div class="row justify-content-center pt-2">
          <div class="col col-sm-6 text-center">
            <div v-if="cdesignErorr" class="alert alert-danger p-1 mx-4 br-radius"> {{ message }} </div>
          </div>
        </div>
        <!--begin:breadcrumb-->
        <div class="row pt-2 pb-3">
          <div class="col ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <router-link to="/"><img src="/assets/icons/back-arrow.svg" alt="icon"
                      class="icon-class-bg" /></router-link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <!--end:breadcrumb-->
        <div class="row pt-2">
          <div class="col-6 col-sm-2 col-xxl-1">
            <div class="space">CREATE DESIGN | {{ postSizeTitle }}</div>
            <div class="line-designs px-3"></div>
          </div>
        </div>
        <!--begin:designs-->
        <div class="row">
          <div class="col-12 col-sm-6 col-md-8 col-xxl-4 updesingl">
            <div class="card border-hidden">
              <div class="card-body">
                <form class="p-2" @submit.prevent="submitForm()">
                  <div class="row">
                    <div class="col-12 col-sm-4 col-md-4">
                      <label for="" class="font-14">Brands</label>
                      <select class="form-select font-12 bg-Gray" v-model="brand" @change="updateImage">
                        <option value="0">Select Brand</option>
                        <option v-for="(val, index) in row.brands" :key="index" :value="val.id">{{ val.name }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="col-6 col-sm-3 col-md-6">
                      <label for="" class="font-14"> Category</label>
                      <select name="" class=" bg-Gray form-select font-12" v-model="types" @change="updateImage" id="">
                        <option value="">Select Category</option>
                        <option v-for="(val, index) in row.temp_type" :key="index" :value="val.id">{{ val.name }}
                        </option>
                      </select>
                    </div> -->
                    <div class="col-12 col-sm-4 col-md-4">
                      <label for="" class="font-14">Content Tones</label>
                      <select name="" v-model="tones" @change="updateImage" class="bg-Gray form-select font-12" id="">
                        <option value="0">Select Tones</option>
                        <option v-for="(val, index) in row.tones" :key="index" :value="val.id">{{ val.title }}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 col-sm-4 col-md-4">
                      <label for="" class="font-14">Language</label>
                      <select name="" v-model="language" @change="updateImage" class="form-select bg-Gray font-12"
                        id="">
                        <option value="0">Select Language</option>
                        <option v-for="(val, index) in row.output_languages" :key="index" :value="val.id">{{ val.name
                          }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- <div class="row pt-2">
                    <div class="col bg-Gray">
                      <div class="p-2 pt-0">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-check form-switch font-12 space pt-4 text-center text-md-start">
                              <input class="form-check-input" v-model="buttonPrice" @change="updateImage"
                                type="checkbox" id="price" default>
                              <label class="form-check-label" for="price">Include price</label>
                            </div>
                          </div>
                          <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                            <label for="" class="font-12">Price</label>
                            <div class="input-group">
                              <select id="" v-model="currency" @change="updateImage"
                                class="form-select p-0 br-color ps-1" :disabled="!buttonPrice">
                                <option v-for="(val, index) in row.currencies" :key="index" :value="val.id">{{
                                  val.short_name }}</option>
                              </select>
                              <input type="text" :disabled="!buttonPrice"
                                class="form-control p-0 w-20 br-color text-center" v-model="includePrice"
                                @change="updateImage" aria-label="Text input with dropdown button">
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-check form-switch pt-4 font-12 space text-center text-md-start">
                              <input class="form-check-input" type="checkbox" v-model="buttonSale" @change="updateImage"
                                id="sale" default>
                              <label class="form-check-label" for="sale">Include Sale</label>
                            </div>
                          </div>
                          <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                            <label for="" class="font-12">Percentage</label>
                            <div class="input-group">
                              <span
                                :class="(buttonSale) ? 'input-group-text bg-white p-0 br-color ps-2' : 'input-group-text bg-feature p-0 br-color ps-2'">%</span>
                              <input type="text" class="form-control p-0 br-left br-color text-center"
                                v-model="includeSale" @change="updateImage" aria-label="Text input with dropdown button"
                                :disabled="!buttonSale">
                            </div>
                          </div>
                        </div>
                        <div class="row pt-2">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-6 pt-4 text-center text-md-start">
                            <div class="form-check form-switch font-12">
                              <input class="form-check-input" type="checkbox" v-model="buttonBefore_After"
                                @change="updateImage" id="priceBA" default>
                              <label class="form-check-label" for="priceBA">Include Price Before & After</label>
                            </div>
                          </div>
                          <div class="col-12 col-md-12 col-lg-2 col-sm-12">
                            <label for="" class="font-12">Currency</label>
                            <select id="" v-model="currency" @change="updateImage" class="form-select p-0 br-color ps-2"
                              :disabled="!buttonBefore_After">
                              <option v-for="(item, index) in row.currencies" :key="index" :value="item.id">{{
                                item.short_name }}</option>
                            </select>
                          </div>
                          <div class="col-12 col-md-12 col-lg-2 col-sm-12">
                            <label for="" class="font-12 space">Price Before</label>
                            <input type="text" v-model="priceBefore" @change="updateImage"
                              class="form-control p-0 br-color text-center" :disabled="!buttonBefore_After">
                          </div>
                          <div class="col-12 col-md-12 col-lg-2 col-sm-12">
                            <label for="" class="font-12 space">Price After</label>
                            <input type="text" v-model="priceAfter" @change="updateImage"
                              class="form-control p-0 br-color text-center" :disabled="!buttonBefore_After">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                            <div class="form-check form-switch font-12 space pt-4 text-center text-md-start">
                              <label class="form-check-label" for="offer">Include Offer</label>
                              <input class="form-check-input" type="checkbox" v-model="buttonOffer"
                                @change="updateImage" id="offer" default>
                            </div>
                          </div>
                          <div class="col-12 col-md-12 col-lg-6 col-sm-12">
                            <label for="" class="font-12">What Is Your Offer</label>
                            <input type="text" class="form-control py-0 px-3 br-color" v-model="includeOffer"
                              @change="updateImage" :disabled="!buttonOffer"
                              aria-label="Text input with dropdown button">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class="row pt-2">
                    <div class="col-12 col-sm-12 col-md-12">
                      <label for="" class="font-14">Describe Your Design ?</label>
                      <textarea name="" v-model="topic" @change="updateImage" class="form-control border-hidden bg-Gray"
                        placeholder="Social Media Post to Promote Our New Product" rows="8"></textarea>
                      <div class="error-feedback red_color" v-if="V$.topic.$error">
                        {{ V$.topic.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-3 pt-3 imagebox" v-for="(x, index) in imagesView" :key="index">
                      <div class="image-container-box position-relative">
                        <img class="img-fluid-box" :src="x" alt="Image">
                        <div class="overlay d-flex justify-content-center align-items-center">
                          <div class="text-center">
                            <span class="action-text" @click="changeImage(index)">Change Image</span>
                            <br />
                            <a @click.prevent="deleteImage(index)" class="delete-icon btn">
                              <i class="fa fa-trash-can"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="m-0 main-button">
                  <div class="row">
                    <div class="col">
                      <div class="card border-hidden bg-Gray">
                        <div class="card-body">
                          <h6 class="card-title main-image">Main Image /s</h6>
                          <div class="row">

                            <div class="col-12 col-sm-3 col-md-4 col-xxl-4 pb-2">
                              <div class="main-button" id="openLibrary" @click="getImagelist()" data-bs-toggle="modal"
                                data-bs-target="#libraryModal">
                                <i class="fa-solid fa-plus payment-color"></i> Add Images /s
                              </div>
                            </div>

                            <div class="col-12 col-sm-3 col-md-4 col-xxl-4 pb-2">
                              <div class="main-button" @click="openImageAIModal">
                                <i class="fa-solid fa-plus payment-color"></i> Generate Image
                              </div>
                            </div>
                            <div v-if="isLoading">
                              <loader />
                            </div>
                            <div v-else class="col-12 col-md-4 text-end col-sm-9 ">
                              <button class="btn btn-sm btn-generate" type="submit">
                                <img src="/assets/images/Vector.png" class="images-width" alt="">
                                GENERATE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xxl-8 pt-2  middle-iamge updesingl">
            <div class="row">
              <div class="col text-center">
                <img :src="currentImage" class="images-width-h" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!--end:designs-->
        <!--begin:Modals-->
        <div class="row">
          <div class="col">
            <!--begin:library-->
            <div class="row">
              <div class="col">
                <div class="modal fade" id="libraryModal" tabindex="-1" aria-labelledby="libraryModalLabel"
                  aria-hidden="true">
                  <div
                    class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-dialog-scrollable ps-4 pe-4 library-width">
                    <div class="modal-width modal-content">
                      <div class="modal-header p-3">
                        <h5 class="modal-title" id="libraryModalLabel">Library</h5>
                        <button type="button" class="btn-close" id="closelibra" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                      </div>
                      <div v-if="upload_img_library" class="alert alert-danger mx-4 br-radius text-center p-1">{{
                        message }}</div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                            <select class="form-select font-14" v-model.number="brand">
                              <option value="0">Select Brand Name</option>
                              <option v-for="(val, index) in row.brands" :key="index" :value="val.id">{{ val.name }}
                              </option>
                            </select>
                          </div>
                          <div class="col-12 col-sm-6 text-center">
                            <loader class="w-50" v-if="uploadloader" />
                            <button v-else type="button" class="btn bg-button px-4"
                              @click="uploadImage()">Upload</button>
                          </div>
                        </div>
                        <div class="row pt-1">
                          <div class="col-12">
                            <div style="position: relative;">
                              <!-- Centered Text and Icon above DropZone -->
                              <div class="d-flex justify-content-center align-items-center pb-2">
                                <div class="form-text d-flex align-items-center  text-center">
                                  <span><i class="fa-solid fa-cloud-arrow-up font-26 pe-2"></i> Drag a image here or
                                    click to choose a file.</span>
                                </div>
                              </div>
                              <DropZone @addedFile="onFileAdd" message="Upload your files here" :maxFiles="Number(1)"
                                :maxFileSize="10000000" url="http://localhost" :uploadOnDrop="false"
                                :multipleUpload="true" :parallelUpload="3" />
                            </div>
                          </div>
                        </div>
                        <div class="row p-2 font-14 add-image align-items-center">
                          <div class="col-12 col-sm-6 text-center text-sm-start mb-2 mb-sm-0">
                            <span>RECENT UPLOADS</span>
                            <div class="line col-12 col-sm-6 text-center text-sm-start mb-sm-0 mt-0 mb-0 m-1"></div>
                          </div>
                          <div class="col-12 col-sm-6 text-center text-sm-end">
                            <div class="dropdown">
                              <button class="btn btn btn-filter dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Filter
                              </button>
                              <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                                <li>
                                  <label for="">Brand Name</label>
                                  <select name="" v-model="brandFilter" class="form-select" id="">
                                    <option value="">Not selected</option>
                                    <option v-for="(brand, index) in row.brands" :key="index" :value="brand.id">{{
                                      brand.name
                                    }}</option>
                                  </select>
                                </li>
                                <li>
                                  <label for="">Date</label>
                                  <select name="" class="form-select" v-model="date" id="">
                                    <option value="">Not selected</option>
                                    <option value="create_at">Ascending</option>
                                    <option value="-create_at">Descending</option>
                                  </select>
                                </li>
                                <li class="py-3 text-center">
                                  <button type="button" @click.prevent="filterGallery()"
                                    class="btn btn-primary px-4">Filter</button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- sawaf -->
                        <div class="row" v-if="loadLibrary">
                          <div class="col text-center">
                            <loadersoda />
                          </div>
                        </div>
                        <div class="row pt-3 library-h overflow-auto" style="max-height: 400px;" ref="scrollContainer"
                          @scroll="handleScroll">
                          <div class="col-6 col-sm-4 col-md-3 p-2" v-for="(val, index) in images" :key="index">
                            <label class="mouse checkImage" style="display: block;">
                              <!-- Ensure the checkbox and image are displayed together properly -->
                              <input @change="getImage(val.image, val.id)" type="checkbox" v-model="recentImage"
                                :value="val.id" class="form-check-input radio_moved"
                                style="position: absolute; z-index: 1;" />

                              <!-- Image container with a fixed aspect ratio -->
                              <div class="image-container">
                                <img class="img-fluid" :src="val.image" alt="">
                              </div>
                            </label>
                          </div>

                        </div>
                      </div>
                      <div class="modal-footer">
                        <span class="add-image"></span>
                        <button class="btn bg-button px-4 btn-add font-color button-okay"
                          @click="closeLibrary()">Okay</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end:library-->
            <!--end:library-->
            <!--begin:generateAI-->
            <div class="row">
              <div class="col">
                <div class="modal fade" id="imageAI" tabindex="-1" aria-labelledby="imageAILabel" aria-hidden="true"
                  @shown.bs.modal="resetImageAIModal" ref="imageAIModal">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="imageAILabel">
                          Generate Image AI
                        </h5>
                        <button type="button" class="btn-close" id="closeGenerate" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                      </div>
                      <div v-if="uploadError" class="alert alert-danger text-center">
                        {{ message }}
                      </div>
                      <div class="modal-body">
                        <!-- Brand Selection -->
                        <div class="row py-3">
                          <div class="col text-center">
                            <label for="">Choose your brand</label>
                            <select class="form-select font-14" v-model="brandAI"
                              :class="{ 'is-invalid': V$.brandAI.$error }">
                              <option value="0">Select Brand Name</option>
                              <option v-for="(val, index) in row.brands" :key="index" :value="val.id">
                                {{ val.name }}
                              </option>
                            </select>
                            <!-- Validation Error Message -->
                            <div class="invalid-feedback" v-if="V$.brandAI.$error">
                              {{ V$.brandAI.$errors[0].$message }}
                            </div>
                          </div>
                        </div>
                        <!-- Description Textarea -->
                        <div class="row">
                          <div class="col text-center">
                            <label for="">Describe Your Image ?</label>
                            <textarea v-model="descriptAI" class="form-control" rows="4"
                              :class="{ 'is-invalid': V$.descriptAI.$error }"
                              placeholder="Enter description here"></textarea>
                            <!-- Validation Error Message -->
                            <div class="invalid-feedback" v-if="V$.descriptAI.$error">
                              {{ V$.descriptAI.$errors[0].$message }}
                            </div>
                          </div>
                        </div>
                        <!-- Gallery AI Images -->
                        <div class="row pt-3">
                          <div class="col text-center" v-for="(val, index) in galleryAI" :key="index">
                            <label class="mouse">
                              <input type="radio" :value="val.id" v-model="id_ImageAI"
                                class="form-check-input radio_moved" />
                              <img class="img-uplaod img-fluid" :src="val.image" alt="" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="generate-Ai"></div>
                      <!-- Modal Footer with Buttons -->
                      <div class="modal-footer">
                        <loader v-if="loaderAI" />
                        <button v-else type="button" class="btn bg-button-h" @click="generateAI()">
                          GENERATE AI
                        </button>
                        <!-- New "Select this" button -->
                        <button v-if="!loaderAI && id_ImageAI" type="button" class="btn bg-button-h"
                          @click="selectGeneratedImage()">
                          Select this
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end:generateAI-->
          </div>
        </div>
        <!--end:Modals-->
      </div>
    </div>
  </div>

</template>
<script>
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
import { DropZone } from "dropzone-vue";
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import globalErrorHandler from "@/globalErrorHandler";


export default defineComponent({
  name: "cdesign",
  data() {
    return {
      post_id: this.$route.params.key,
      loading: false,
      cdesignErorr: false,
      message: "",
      row: {},
      brand: 0,
      types: "",
      tones: 0,
      language: 0,
      topic: "",
      hock: "",
      head: "",
      caption: "",
      action: "",
      brandColors: "",
      brandFonts: "",
      brandLogo: "",
      website: "",
      adesign: "",
      pcolor: "",
      scolor: "",
      images: [],
      uploadloader: false,
      upload_img_library: false,
      uploadError: false,
      recentImage: [],
      generateAI_Error: false,
      loaderAI: false,
      galleryAI: [],
      brandAI: 0,
      descriptAI: "",
      id_ImageAI: 0,
      isLoading: false,
      imagesView: [],
      postSizes: 0,
      brandFilter: 0,
      date: "",
      nextPage: false,
      prevPage: false,
      includeSale: 0,
      includeOffer: "",
      includePrice: 0,
      currency: 0,
      priceBefore: 0,
      priceAfter: 0,
      buttonPrice: false,
      buttonSale: false,
      buttonOffer: false,
      buttonBefore_After: false,
      autoLoadAttempts: 0,
      loadLibrary: false,
      filterLoading: false,
      currentImage: '/assets/images/soda-man.svg',
      V$: useVuelidate(),
    };
  },

  validations() {
    return {
      topic: {
        required: helpers.withMessage('Description is required', required),
      },
      brandAI: {
        required: helpers.withMessage('Brand is required', value => value !== 0 && !!value),
      },
      descriptAI: {
        required: helpers.withMessage('Description is required', required),
      },
    }
  },

  components: {
    DropZone,
  },
  computed: {
    ...mapGetters(["BASE_URL", 'POST_SIZES']),
    postSizeTitle() {
      const postSize = this.POST_SIZES.find(ps => ps.id == Number(this.post_id));
      return postSize ? postSize.title : '';
    },
  },
  methods: {
    getData() {
      this.$http
        .get("/api/templates/init/" + this.post_id)
        .then((res) => {
          // console.log(res);
          this.row = res.data;
          if (this.row.brands.length > 0) {
            this.brand = this.row.brands[0].id; // Set the brand to the first value
          }
        }).catch((err) => {
          this.message = globalErrorHandler(err.data)[0];
          this.cdesignErorr = true;
          this.loading = false;
          this.isLoading = false;
          this.loadLibrary = false;
          window.scroll(0, 0);
        });
    },

    submitForm() {
      this.V$.$validate();
      this.isLoading = true;
      window.scroll(0, document.body.scrollHeight);
      this.cdesignErorr = false;
      this.$http.post("/api/templates/suggest/", {
        post_type: "welcome",
        brand: this.brand,
        temp_type: "13",
        tone: this.tones,
        post_size: this.post_id,
        output_language: this.language,
        topic: this.topic,
        brandColors: this.brandColors,
        brandFonts: this.brandFonts,
        brandLogo: this.brandLogo,
        website: this.website,
        adesign: this.adesign,
        pcolor: this.pcolor,
        scolor: this.scolor,
        image_id: this.recentImage,
        brand_id: this.brand,
        description: this.descriptAI,
        id_ImageAI: this.id_ImageAI,
        price: this.buttonPrice ? this.includePrice : "",
        currency:
          this.buttonPrice || this.buttonBefore_After ? this.currency : "",
        include_sale: this.buttonSale ? this.includeSale : "",
        include_offer: this.buttonOffer ? this.includeOffer : "",
        price_after: this.buttonBefore_After ? this.priceAfter : "",
        price_before: this.buttonBefore_After ? this.priceBefore : "",
      }).then((res) => {
        this.isLoading = false;
        // console.log(res);
        this.$router.replace({
          name: "updesign",
          params: { id: res.data.data.id },
        });
        window.scroll(0, 0);
      }).catch((err) => {
        this.message = globalErrorHandler(err.data)[0];
        this.cdesignErorr = true;
        this.isLoading = false;
        this.loading = false;
        window.scroll(0, 0);
      });

    },

    uploadImage() {
      this.message = "";
      this.cdesignErorr = false;
      this.upload_img_library = false;
      this.uploadError = false;
      const formData = new FormData();
      formData.append("image", this.image);
      formData.append("brand", this.brand);
      if (!this.brand) {
        this.message = "You must select brand";
        this.upload_img_library = true;
        return false;
      }

      if (!this.image || !(this.image instanceof File) || this.image.size === 0) {
        this.message = "You must choose the image";
        this.upload_img_library = true;
        return false;
      }
      this.upload_img_library = false;
      this.uploadloader = true;
      this.$http.post("/api/templates/images/upload/", formData).then((res) => {
        // console.log(res);
        this.uploadloader = false;
        this.upload_img_library = false;
        document.getElementsByClassName("gg-close")[0].click();
        this.getImagelist();
      }).catch((err) => {
        this.message = globalErrorHandler(err.data)[0];
        this.cdesignErorr = true;
        this.loading = false;
        this.uploadloader = false;
        this.isLoading = false;
        this.upload_img_library = true;
        window.scroll(0, 0);
      });
    },

    closeLibrary() {
      document.getElementById("closelibra").click();
    },

    // Fetch initial set of images (8 images)
    getImagelist() {
      this.message = "";
      this.cdesignErorr = false;
      this.upload_img_library = false;
      this.uploadError = false;
      this.loadingLibrary = true;
      this.autoLoadAttempts = 0; // Reset auto-load attempts
      this.$http
        .get("/api/templates/images/", { params: { limit: 8 } })
        .then((res) => {
          this.images = res.data.results;
          this.nextPage = res.data.next; // Capture the next page URL
          this.loadingLibrary = false;
          this.$nextTick(() => {
            this.checkIfMoreImagesNeeded();
          });
        })
        .catch((err) => {
          this.message = globalErrorHandler(err.data)[0];
          this.cdesignErorr = true;
          this.loading = false;
          this.loadingLibrary = false;
          this.uploadError = true;
          this.isLoading = false;
          window.scroll(0, 0);
        });
    },

    // Fetch more images when scrolling
    loadMoreImages() {
      if (this.nextPage) {
        this.loadMore = true;
        this.$http
          .get(this.nextPage)
          .then((res) => {
            this.images.push(...res.data.results); // Append new images
            this.nextPage = res.data.next; // Update the next page URL
            this.loadMore = false;
            this.$nextTick(() => {
              this.checkIfMoreImagesNeeded();
            });
          })
          .catch((err) => {
            this.message = globalErrorHandler(err.data)[0];
            this.cdesignErorr = true;
            this.loading = false;
            this.isLoading = false;
            this.loadMore = false;
            window.scroll(0, 0);

          });
      }
    },
    checkIfMoreImagesNeeded() {
      const scrollContainer = this.$refs.scrollContainer;
      // Check if container is scrollable
      if (
        scrollContainer.scrollHeight <= scrollContainer.clientHeight &&
        this.nextPage &&
        this.autoLoadAttempts < 5 // Limit to prevent infinite loading
      ) {
        this.autoLoadAttempts++;
        this.loadMoreImages();
      }
    },
    // Handle scroll event on the library-h div
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
      const bottomPosition = scrollContainer.scrollHeight;
      if (scrollPosition >= bottomPosition - 10 && this.nextPage) {
        this.loadMoreImages();
      }
    },

    uploadFile(files) {
      this.image = files[0];
    },
    generateAI() {
      this.V$.brandAI.$touch();
      this.V$.descriptAI.$touch();
      if (this.V$.brandAI.$error || this.V$.descriptAI.$error) {
        return;
      }

      this.loaderAI = true;
      this.$http
        .post("/api/templates/images/generate/", {
          brand_id: this.brand,
          description: this.descriptAI,
          post_size: this.post_id,
        })
        .then((res) => {
          // console.log(res);
          this.galleryAI = res.data.images;
          this.loaderAI = false;
        })
        .catch((err) => {
          this.message = globalErrorHandler(err.data)[0];
          this.cdesignErorr = true;
          this.loading = false;
          this.generateAI_Error = true;
          this.isLoading = false;
          this.loaderAI = false;
          window.scroll(0, 0);
        });
    },
    closeGenerateAI() {
      document.getElementById("closeGenerate").click();
    },
    getImage(image, id) {
      if (this.imagesView.length > 3) {
        this.imagesView.splice(3, 1);
        this.recentImage.splice(3, 1);
      }
      if (this.recentImage.indexOf(id) != -1) {
        this.imagesView.push(image);
      } else {
        if (this.imagesView.indexOf(image) != -1) {
          this.imagesView.splice(this.imagesView.indexOf(image), 1);
        }
      }
    },
    changeImage(index) {
      document.getElementById("openLibrary").click();
      this.imagesView.splice(index, 1);
      this.recentImage.splice(index, 1);
    },
    deleteImage(index) {
      swal(
        {
          title: "Are you sure?",
          text: "you will not be able to recover image",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel",
          closeOnConfirm: false,
          // closeOnCancel: false
        },
        () => this.delImage(index)
      );
    },
    delImage(index) {
      this.imagesView.splice(index, 1);
      swal("Deleted!", "you image has been deleted.", "success");
    },

    onFileAdd(e) {
      this.image = e.file;
      this.uploadImage();
    },
    sortImages() {
      if (this.date === 'create_at') {
        this.images.sort((a, b) => new Date(a.create_at) - new Date(b.create_at));
      } else if (this.date === '-create_at') {
        this.images.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
      }
    },
    filterGallery() {
      this.filterLoading = true;
      this.autoLoadAttempts = 0; // Reset auto-load attempts
      let params = {};
      params.limit = 8;
      if (this.brandFilter) {
        params.brand = this.brandFilter;
      }
      this.$http
        .get("/api/templates/images/", { params })
        .then((res) => {
          this.images = res.data.results;
          this.nextPage = res.data.next;
          this.filterLoading = false;
          this.sortImages(); // Apply Frontend-side sorting
          this.$nextTick(() => {
            this.checkIfMoreImagesNeeded();
          });
        })
        .catch(err => {
          this.message = globalErrorHandler(err.data)[0];
          this.cdesignErorr = true;
          this.loading = false;
          this.filterLoading = false;
          this.isLoading = false;
          window.scroll(0, 0);
        });
    },

    getNext() {
      this.nextPage += 1;
      // console.log(this.nextPage);
    },
    getPrev() {
      this.prevPage -= 1;
      // console.log(this.prevPage);
    },
    updateImage() {
      if (
        this.brand !== 0 ||
        this.types !== "13" ||
        this.tones !== 0 ||
        this.language !== 0 ||
        this.topic.trim() !== "" ||
        this.buttonPrice ||
        this.buttonSale ||
        this.buttonOffer ||
        this.buttonBefore_After

      ) {
        this.currentImage = '/assets/images/soda-man2.svg';
      } else {
        this.currentImage = '/assets/images/soda-man.svg';
      }
    },
    selectGeneratedImage() {
      const selectedImage = this.galleryAI.find(image => image.id === this.id_ImageAI);
      if (selectedImage) {
        if (this.imagesView.length >= 4) {
          // Limit to 4 images
          this.imagesView.splice(3, 1);
          this.recentImage.splice(3, 1);
        }
        if (!this.recentImage.includes(selectedImage.id)) {
          this.imagesView.push(selectedImage.image);
          this.recentImage.push(selectedImage.id);
        }
        this.closeGenerateAI();
      }
    },
    resetImageAIModal() {
      // Reset modal data properties
      this.brandAI = 0;
      this.descriptAI = '';
      this.id_ImageAI = 0;
      this.galleryAI = [];
      this.loaderAI = false;

      // Reset validation state for modal fields
      this.V$.brandAI.$reset();
      this.V$.descriptAI.$reset();
    },
    openImageAIModal() {
      this.resetImageAIModal();
      // Open the modal programmatically using Bootstrap's modal API
      const modalElement = new bootstrap.Modal(document.getElementById('imageAI'));
      modalElement.show();
    },


  },


  created() {
    this.getData();
    this.getImagelist();
  },
  watch: {
    '$route.params.key'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.post_id = newVal;
        this.getData();
      }
    },
  },

  beforeRouteUpdate(to, from, next) {
    // Update the post_id
    this.post_id = to.params.key;

    // Re-fetch data or perform necessary updates
    this.getData();

    // Proceed with the navigation
    next();
  },
  mounted() {
    // Access the modal DOM element via ref
    const modalElement = this.$refs.imageAIModal;
    if (modalElement) {
      modalElement.addEventListener('show.bs.modal', this.resetImageAIModal);
    }
  },

  unmounted() {
    // Remove scroll event listener when component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Remove event listener to prevent memory leaks
    const modalElement = this.$refs.imageAIModal;
    if (modalElement) {
      modalElement.removeEventListener('show.bs.modal', this.resetImageAIModal);
    }
  },

});
</script>
<style scoped>
.library-h {
  max-height: 70vh;
  /* Adjust the height as needed for scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.library-width {
  min-width: 48% !important;
}

.button-okay {
  background-color: #f70081;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

/* Align the form elements better and ensure spacing is consistent */
.form-check-label {
  margin-left: 0.5rem;
  font-size: 12px;
}

.input-group {
  display: flex;
  align-items: center;
}

.form-check-input {
  transform: scale(1.2);
}

.form-label {
  font-size: 0.9rem;
  font-weight: bold;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .form-label {
    font-size: 0.85rem;
  }
}

.bgcustom {
  background-color: #ffffff;
}

.img-fluid-preview {
  width: 100%;
  height: auto;
  border-radius: 3%;
}

.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}



.images-width-h {
  max-width: 350px;
}

/* For image Box */

.imagebox {
  position: relative;
}

.image-container-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 150%;
  border-radius: 8px;
}

.img-fluid-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(214, 214, 214, 0.267);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container-box:hover .overlay {
  opacity: 1;
}

.action-text {
  background-image: linear-gradient(45deg, #E26600, #F70081, transparent);
  color: white;
  font-size: 10px;
  opacity: 0.8;
  font-weight: bolder;
  cursor: pointer;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px;
}

.delete-icon {
  color: #ff0000;
  font-size: 18px;
  margin-top: 15px;
  cursor: pointer;
}
</style>