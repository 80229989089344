<template>
  <nav id="sidebarMenu" class="collapse sidebar d-lg-flex sticky-top justify-content-start top-0 pt-5 text-uppercase ">
    <div class="menu-items">
      <div class="list-group list-group-flush left-menu mt-4">
        <div class="pb-2 links">
          <router-link to="/" :class="('home'.indexOf(GET_ROUTE) != -1) ? 'active' : ''">
            <img
              :src="('home'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Designs-Colors.png' : '/assets/images/Designs-Black.png'"
              class="w-bubble" alt="">
            <span class="link-name">DESIGNS</span>
          </router-link>
        </div>
        <div class=" pb-2 links">
          <router-link to="/library" :class="('library'.indexOf(GET_ROUTE) != -1) ? 'active' : ''">
            <img
              :src="('library'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Library-Colors.png' : '/assets/images/Library-Black.png'"
              class="w-bubble" alt="">
            <span class="link-name">Library</span>
          </router-link>
        </div>
        <div class=" pb-2 links">
          <router-link to="/brandview" :class="(['brandview', 'cbrand'].indexOf(GET_ROUTE) != -1) ? 'active' : ''">
            <img
              :src="('brandview'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Brands-Colors.png' : '/assets/images/Brands-Black.png'"
              class="w-bubble" alt="">
            <span class="link-name">Brands</span>
          </router-link>
        </div>
        <div class="text-center pb-2 links">
          <router-link to="/users" :class="(['users'].indexOf(GET_ROUTE) != -1) ? 'active' : ''">
            <img
              :src="('users'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Users-Colors.png' : '/assets/images/Users-Black.png'"
              class="w-bubble" alt="">
            <span class="link-name">Users</span>
          </router-link>
        </div>
        <div v-if="access_Role == 'owner'" class="text-center pb-2 links">
          <router-link to="/billing" :class="(['billing'].indexOf(GET_ROUTE) != -1) ? 'active' : ''">
            <img
              :src="('billing'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Billing colors.png' : '/assets/images/Billing-Black.png'"
              class="w-bubble" alt="">
            <span class="link-name">Billing</span>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'
let menubar = document.querySelector('#sidebarMenu');

export default {
  data() {
    return {
      access_Role: localStorage.getItem('role'),
    }
  },
  computed: {
    ...mapGetters(['GET_ROUTE']),
  },

  methods: {
    openMenu() {
      document.getElementById('sidebarMenu').classList.toggle('close-menu');
    }
  }
}
</script>
