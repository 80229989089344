<template>
  <div class="container-fluid bgcustom pt-3 pb-5 mt-2">
    <div class="card shadow rounded-5 hborder mx-auto" style="max-width: 1400px;">
      <div class="card-body text-center">
        <img src="/assets/images/logo-soda.svg" class="logo-width" alt="">
      </div>
      <div class="card-body text-center">
        <!-- Error or success message -->
        <div v-if="message" class="alert alert-info container w-50">{{ message }}</div>
      </div>
      <div class="card-body text-center">
        <h5 class="fw-lighter mb-3 h5 colorfontdark">Hi {{ firstName }} {{ lastName }},</h5>
        <h2 class="fw-bold mb-3 text-danger text-center font-bold">Paid Plan Suspended</h2>
        <hr class="divider mx-auto text-center w-50" />
        <h4 class="fw-bold text-center mb-5 lh-lg">Your plan has been suspended because your last payment failed.</h4>

        <!-- Reactivate button to open modal -->
        <div class="text-center">
          <button class="btn btn-generate w-50 font-20" @click="openAddCardModal">Reactivate</button>
        </div>

        <!-- Logout link -->
        <div class="mt-4 text-center">
          <p>
            <a class="mouse" @click="methodlogOut">
              <i class="fa-solid fa-right-from-bracket p-color"></i> Log Out
            </a>
          </p>
        </div>
      </div>
    </div>

    <!-- Add Card Modal -->
    <div class="modal fade" id="addCardModal" tabindex="-1" aria-labelledby="addCardModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCardModalLabel">Add New Card</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- Show loading indicator while processing -->
            <div v-if="isLoading" class="text-center">
              <loader />
            </div>
            <!-- PaymentForm component for adding card details -->
            <PaymentForm v-else :then-promising="handleCardAdded" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentForm from '@/views/Billing/PaymentForm.vue';
import { resubscribeSubscription } from "@/apis";
import globalErrorHandler from "@/globalErrorHandler"; // Import global error handler

export default {
  name: 'Unpaid_Credit',
  components: {
    PaymentForm,
  },
  data() {
    return {
      subscribeError: false,
      message: "",
      isLoading: false, // Track loading state
    };
  },
  computed: {
    ...mapGetters(['GET_DATA_USER', 'GET_BASE_SUBSCRIPTION']),
    firstName() {
      return this.GET_DATA_USER.firstName;
    },
    lastName() {
      return this.GET_DATA_USER.lastName;
    },
  },
  methods: {
    openAddCardModal() {
      const modalElement = document.getElementById("addCardModal");
      const addCardModal = new bootstrap.Modal(modalElement);
      addCardModal.show();
    },
    async handleCardAdded() {
      this.isLoading = true;
      try {
        // Check if subscription ID is available
        const subscriptionId = this.GET_BASE_SUBSCRIPTION?.id;
        if (!subscriptionId) {
          throw new Error('No subscription ID available for resubscription.');
        }

        // Call resubscribeSubscription with the ID
        await resubscribeSubscription(subscriptionId, { pricing: this.GET_BASE_SUBSCRIPTION?.pricing?.id });

        // Display success message and close modal
        this.message = "Your subscription has been successfully reactivated!";
        this.isLoading = false;

        const modalElement = document.getElementById("addCardModal");
        const addCardModal = bootstrap.Modal.getInstance(modalElement);
        addCardModal.hide();

        // Redirect to home after a delay
        setTimeout(() => {
          this.$router.push({ path: '/' }).then(() => {
            window.location.reload();
          });
        }, 3000);
      } catch (error) {
        // Handle and display error using globalErrorHandler
        this.isLoading = false;
        const errors = globalErrorHandler(error);
        this.message = errors.join(" "); // Join multiple error messages if any
        console.error("Error during resubscription:", errors);
      }
    },
    methodlogOut() {
      this.$http.get("/api/auth/logout/")
        .then(() => {
          this.$store.commit('REMOVE_USER_DATA');
          window.location.href = "/login";
        })
        .catch(err => console.error(err));
    },
  },
};
</script>

<style scoped>
.bgcustom {
  background-color: #f5f5f5;
}

.card {
  background-color: #ffffff;
  border-radius: 17px;
}

.logo-width {
  width: 150px;
}
</style>
