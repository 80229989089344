<template>
  <div>
      <!-- <img src="/images/loading-screen.gif" class="loader_w" alt=""> -->
      <img src="/assets/images/loding.gif" class="loader_w" alt="">
  </div>
</template>
<script>
export default {
  name: "LoaderSoda",
}
</script>
