<template>
  <div class="container-fluid bgcustom pt-3 pb-0 mt-2">
    <div class="card shadow rounded-5 hborder mx-auto w-75" style="max-width: 1400px;">
      <div class="card-body text-center">
        <img src="/assets/images/logo-soda.svg" class="logo-width" alt="">
      </div>
    </div>
  </div>
  <div class="container-fluid bgcustom pt-3 pb-5 mt-0">
    <div class="card shadow rounded-5 hborder mx-auto w-75" style="max-width: 1400px;">
      <div class="card-body text-center">
        <div class="container w-75" v-if="messages && messages.length">
          <div v-for="(message, index) in messages" :key="index" class="alert"
            :class="message.includes('successfully') ? 'alert-success' : 'alert-danger'">
            {{ message }}
          </div>
        </div>
      </div>
      <div class="card-body text-center">
        <i class="fa fa-envelope custom-icon-color icon-w mb-3"></i>
        <h5 class="fw-lighter mb-3 h5 colorfontdark text-uppercase">Verify your email address</h5>
        <h6 class="text-center text-capitalize lh-base">Please check your inbox and enter the verification code below to
          verify your email address.</h6>
        <form action="" @submit.prevent="verifyEmail">
          <div class="row">
            <div class="col py-2">
              <input type="text" class="form-control" placeholder="Please Enter Code Here" name="code" v-model="code"
                autocomplete="off" />
            </div>
          </div>
          <div class="row">
            <div class="col pt-2 text-center">
              <button type="submit" class="btn btn-generate w-auto">VERIFY</button>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col pt-2 text-center button-h">
              <button type="button" class="btn btn-link p-color" @click="resendVerify" :disabled="countdown > 0">
                Resend Code
              </button>
              <span v-if="countdown > 0">Resend available in {{ countdown }}s</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { verifyEmail, resendVerificationEmail, retrieveBaseSubscriptions } from '@/apis';
import globalErrorHandler from '@/globalErrorHandler';

export default {
  name: "EmailVerify",
  props: {
    islogedin: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      email: this.$route.params.email,
      hasError: false,
      messages: [],
      code: "",
      isLoading: false,
      loading: false,
      countdown: 0, // Timer for resend
      resendInterval: 60, // Time in seconds
    };
  },
  methods: {
    async verifyEmail() {
      this.isLoading = true;
      this.hasError = false;
      this.messages = [];
      try {
        const res = await verifyEmail({ email: this.email, code: this.code });
        this.$gtm.trackEvent({
          event: "Verify_email",
          category: "User",
          action: "Email Verification",
          label: this.email,
          value: 1,
        });
        this.$store.commit('SET_USER_DATA', { token: res.token, user: res.user });
        const resb = await retrieveBaseSubscriptions();
        this.$store.commit('SET_BASE_SUBSCRIPTION', resb);
        window.location.href = '/';
      } catch (err) {
        this.messages = globalErrorHandler(err.data)[0];
        this.hasError = true;
        window.scroll(0, 0);
      } finally {
        this.isLoading = false;
      }
    },
    async resendVerify() {
      if (this.countdown > 0) return;
      this.loading = true;
      this.hasError = false;
      this.messages = [];
      try {
        await resendVerificationEmail({ email: this.email });
        this.messages = ["The code has been sent successfully"];
        this.hasError = true;
        this.startCountdown(); // Start the countdown
      } catch (err) {
        this.messages = globalErrorHandler(err.response?.data?.errors || err.message);
        this.hasError = true;
        window.scroll(0, 0);
      } finally {
        this.loading = false;
      }
    },
    startCountdown() {
      this.countdown = this.resendInterval;
      const interval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    },
  },
  mounted() {
    this.startCountdown(); // Optionally initialize countdown
  },
};
</script>
<style scoped>
.bgcustom {
  background-color: #f5f5f5;
}

.card {
  background-color: #ffffff;
  border-radius: 17px;
}

.icon-w {
  font-size: 30px !important;
}
</style>