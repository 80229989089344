import axios from "axios";

const noAuthAxios = axios.create();

// import { reject } from "core-js/fn/promise";
import {data} from "vue3-tags-input";


const BASE_URL = process.env.VUE_APP_BASE_URL;

const TOKEN = localStorage.getItem("token");

axios.defaults.baseURL = BASE_URL;
if (TOKEN) {
  axios.defaults.headers.common["Authorization"] = `Token ${TOKEN}`;
}

export function subscribe({email, password, ...data}) {


  return new Promise((resolve, reject) => {
    axios
      .post(`/api/subscriptions/register/`, {
        email: email, password: password,
        ...data,
      })
      .then((response) => {

        // Send webhook to Zapier without affecting the main request
        axios.post('https://hooks.zapier.com/hooks/catch/6624901/2i9rpxe/', JSON.stringify({
          email: email,
          ...data,
        }))
          .catch(() => {
            // Ignore any errors from the webhook request
          });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function resendVerificationEmail({email}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/subscriptions/sendVerifyEmail/`, {
        email: email
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function retrieveUserData() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/auth/user/`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function verifyEmail({email, code}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/subscriptions/verifyEmail/`, {
        email: email,
        code: code
      })

      .then((response) => {
        // Send webhook to Zapier without affecting the main request
        axios
          .post('https://hooks.zapier.com/hooks/catch/6624901/2i9azge/', JSON.stringify({
            email: email

          }))
          .catch(() => {
            // Ignore any errors from the webhook request
          });

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.user.access_role);
        axios.defaults.headers.common["Authorization"] = `Token ${response.data.token}`;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function loginSession({email, password}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/auth/login/`, {
        email: email,
        password: password
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.user.access_role);
        axios.defaults.headers.common["Authorization"] = `Token ${response.data.token}`;
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchPlans() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/product?type=plan`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchAddons() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/product?type=addon`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveProductByID(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/product/id/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveProductByName(name) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/product/name/${name}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function listSubscriptions(urlOrPage = 1) {
  return new Promise((resolve, reject) => {
    let request;
    if (typeof urlOrPage === 'string' && urlOrPage.startsWith('http')) {
      // Use the provided URL directly
      request = axios.get(urlOrPage);
    } else {
      // Use the page number to build the request
      request = axios.get(`/api/subscriptions/subscription`, {
        params: {page: urlOrPage},
      });
    }
    request
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveBaseSubscriptions() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/subscription/default`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveSubscriptionByID(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/subscription/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function listPaymentMethods() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/billing/paymentmethod`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function validatePromoCode(code) {
  try {
    const response = await axios.post('/api/billing/validate-promo-code/', {code});
    return response.data;
  } catch (error) {
    throw error;
  }
}


export async function getPriceAfterCoupon(code, pricing) {
  try {
    const response = await axios.post('/api/subscriptions/subscription/price-after-coupon/', {
      code,
      pricing,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}


export function setupIntent() {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/billing/setupintent/`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function addPaymentMethod({payment_method_id, is_default = true}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/billing/paymentmethod/add/`, {
        payment_method_id: payment_method_id, is_default: is_default
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function setDefaultPaymentMethod(id, {is_default = true}) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/billing/paymentmethod/${id}/update/`, {
        is_default: is_default
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function removePaymentMethod(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/billing/paymentmethod/${id}/remove/`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function upgradeSubscription(id, {pricing, code = undefined, email, planName}) {
  data = {pricing}
  if (code !== undefined) data.code = code
  if (email !== undefined) data.email = email
  if (planName !== undefined) data.planName = planName

  return new Promise((resolve, reject) => {
    axios
      .put(`/api/subscriptions/subscription/${id}/upgrade/`, data)

      .then((response) => {
        // Send webhook to Zapier without affecting the main request
        axios.post('https://cors-anywhere.herokuapp.com/https://hooks.zapier.com/hooks/catch/6624901/2s2346v/', {
          subscriptionId: id,
          email: email,
          planName: planName,
        })
          .catch(() => {
            // Ignore any errors from the webhook request
          });


        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getPriceDifference(pricing) {
  try {
    const response = await axios.post('/api/subscriptions/subscription/price-difference/', {
      pricing,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function downloadInvoice(invoiceId) {
  try {
    const response = await axios.get(`/api/billing/invoice/${invoiceId}/download`, {
      responseType: 'blob', // Important for handling binary data
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}


export function downgradeSubscription(id, {pricing}) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/subscriptions/subscription/${id}/downgrade/`, {
        pricing: pricing
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function cancelSubscription(id, {cancellation_type = 'at_period_end'}) {
  // immediately or at_period_end
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/subscriptions/subscription/${id}/cancel/`, {
        cancellation_type: cancellation_type
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function attachAddon({pricing, quantity = 1}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/subscriptions/subscription/attach/`, {
        pricing: pricing,
        quantity: quantity,

      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function listInvoices(urlOrPage = 1) {
  return new Promise((resolve, reject) => {
    let request;
    if (typeof urlOrPage === 'string' && urlOrPage.startsWith('http')) {
      // If urlOrPage is a URL, use it directly
      request = axios.get(urlOrPage);
    } else {
      // Else, build the URL with page parameter
      request = axios.get(`/api/billing/invoice`, {
        params: {page: urlOrPage},
      });
    }
    request
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveInvoiceByID(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/billing/invoice/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function resubscribeSubscription(id, {pricing}) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/subscriptions/subscription/resubscribe/${id}/`, {pricing: pricing})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function retrieveSvgContent(path) {
  return new Promise((resolve, reject) => {
    const baseURL= process.env.VUE_APP_BASE_URL;
    axios
      .get(`${baseURL}${path}`, {
        responseType: "text",
        transformRequest: [(data, headers) => {
          delete headers['Authorization'];
          headers['Content-Type'] = 'image/svg+xml';
          headers['Accept'] = 'image/svg+xml';
          return data;
        }]
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function InviteUser({email, access_role, first_name = undefined, last_name = undefined, mobile = undefined}) {
  return new Promise((resolve, reject) => {
    // Initialize the data object with required properties
    const data = {
      email,
      access_role,
    };

    // Conditionally add optional properties if they are defined
    if (first_name !== undefined) data.first_name = first_name;
    if (last_name !== undefined) data.last_name = last_name;
    if (mobile !== undefined) data.mobile = mobile;

    axios
      .post(`/api/subscriptions/subscription/invetation/create/`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function AcceptInvitations({email, code, password, re_password}) {
  return new Promise((resolve, reject) => {
    const data = {
      email,
      code,
      password,
      re_password
    };

    axios
      .post(`/api/subscriptions/subscription/invetation/accept/`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function listUsers() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/subscriptions/subscription/users`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function updateUser(user_id, {access_role, first_name = undefined, last_name = undefined, mobile = undefined}) {
  return new Promise((resolve, reject) => {
    const data = {
      access_role
    };

    if (first_name !== undefined) data.first_name = first_name;
    if (last_name !== undefined) data.last_name = last_name;
    if (mobile !== undefined) data.mobile = mobile;

    axios
      .put(`/api/subscriptions/subscription/users/update/${user_id}/`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteUser(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/subscriptions/subscription/users/delete/${user_id}/`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function subscriptionModal() {
  return new Promise((resolve, reject) => {
    axios.get('api/popups/').then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function removeSubscriptionPopup(p_id) {
  return new Promise((resolve, reject) => {
    axios.put('api/popups/dont-show-again/', {
      popup_id: p_id,
    }).then((response) => {
      resolve((response.data));
    }).catch((error) => {
      reject(error);
    });
  });
}

export function getData_User() {
  return new Promise((resolve, reject) => {
    axios.get("/api/auth/user/").then((response) => {
      resolve((response.data));
    }).catch((error) => {
      reject(error);
    });
  });
}

export function updata_User_Profile(firstName, lastName, phone, email) {
  return new Promise((resolve, reject) => {
    axios.put("/api/auth/update-profile/", {
      first_name: firstName,
      last_name: lastName,
      mobile: phone,
      email: email,
    }).then((response) => {
      resolve((response.data));
    }).catch((error) => {
      reject((error));
    });
  });
}

export function user_Change_Password(current_Password, new_Password, confirm_Password) {
  return new Promise((resolve, reject) => {
    axios.post("/api/auth/reset-password/", {
      password: current_Password,
      new_password: new_Password,
      re_new_password: confirm_Password,
    }).then((response) => {
      resolve((response.data));
    }).catch((error) => {
      reject((error));
    });
  });
}

export function getSvgURL({path}) {
  return new Promise((resolve, reject) => {
    axios.get(`/api/mediaUrl/${path}`).then((response) => {
      resolve((response.data));
    }).catch((error) => {
      reject(error);
    });
  });
}