<template>
  <div class="container padding-t">
    <main id="main" class="main br-radius">
      <div class="row pt-3">
        <div class="col mx-2">
          <h2 class="p-2">Profile</h2>
        </div>
      </div>
      <div class="row" v-if="loading">
        <div class="col text-center">
          <loadersoda />
        </div>
      </div>
      <section v-else class="section profile">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                <div class="row">
                  <div class="col text-center">
                    <img src="assets/images/Vector-2.png" alt="Profile" class="rounded-circle w-50">
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col">
                    <h2>{{ firstNameList }}</h2>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col">
                  </div>
                </div>
                <div class="social-links mt-2">
                  <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                  <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                  <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                  <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-8">
            <div class="card">
              <div class="card-body pt-3">
                <!-- Bordered Tabs -->
                <ul class="nav nav-tabs nav-tabs-bordered">
                  <li class="nav-item">
                    <button class="nav-link active" id="Profile_Page" data-bs-toggle="tab"
                      data-bs-target="#profile-overview">Overview</button>
                  </li>

                  <li class="nav-item">
                    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                  </li>

                  <li class="nav-item">
                    <button class="nav-link tab-color" data-bs-toggle="tab"
                      data-bs-target="#profile-change-password">Change Password</button>
                  </li>
                </ul>

                <div class="tab-content pt-2">
                  <div class="alert alert-danger p-1 mx-4 br-radius text-center" v-if="hasError"> {{ message }}</div>
                  <div class="tab-pane fade show active profile-overview pt-3" id="profile-overview">
                    <h5 class="card-title">Profile Details</h5>
                    <div class="row pt-2">
                      <div class="col-lg-3 col-md-4 label ">First Name</div>
                      <div class="col-lg-9 col-md-8">{{ firstNameList }} </div>
                    </div>

                    <div class="row pt-2">
                      <div class="col-lg-3 col-md-4 label ">Last Name</div>
                      <div class="col-lg-9 col-md-8">{{ lastNameList }}</div>
                    </div>

                    <div class="row pt-2">
                      <div class="col-lg-3 col-md-4 label">Mobile</div>
                      <div class="col-lg-9 col-md-8">{{ mobileList }}</div>
                    </div>

                    <div class="row pt-2">
                      <div class="col-lg-3 col-md-4 label">Email</div>
                      <div class="col-lg-9 col-md-8">{{ emailList }}</div>
                    </div>
                  </div>

                  <div class="tab-pane fade profile-edit pt-3" id="profile-edit">
                    <!-- Profile Edit Form -->
                    <form @submit.prevent="updateProfile">
                      <div class="alert alert-danger p-1 mx-4 br-radius text-center" v-if="updateError">{{ message }}</div>
                      <!-- <div class="row py-2">
                        <div class="col col-sm-4 text-end">
                          <div>
                            <a href="#" class="btn btn-primary btn-sm" title="Upload new profile image">
                              <i class="fa-solid fa-cloud-arrow-up font-18"></i>
                            </a>
                          </div>
                        </div>
                        <div class="col col-sm-4">
                          <div>
                            <a href="#" class="btn btn-danger btn-sm" title="Remove my profile image">
                              <i class="fa-regular fa-trash-can font-18"></i>
                            </a>
                          </div>
                        </div>
                      </div> -->

                      <div class="row pt-3">
                        <div class="col">
                          <div class="row mb-3">
                            <label class="col-md-4 col-lg-3 col-form-label">First Name</label>
                            <div class="col-md-8 col-lg-9">
                              <input type="text" class="form-control" v-model="firstName" id="lastname" value="">
                            </div>
                          </div>

                          <div class="row mb-3">
                            <label class="col-md-4 col-lg-3 col-form-label">Last Name</label>
                            <div class="col-md-8 col-lg-9">
                              <input type="text" class="form-control" v-model="lastName" id="firstname" value="">
                            </div>
                          </div>

                          <div class="row mb-3">
                            <label for="Phone" class="col-md-4 col-lg-3 col-form-label">Phone</label>
                            <div class="col-md-8 col-lg-9">
                              <input type="text" class="form-control" v-model="mobile" id="Phone" value="">
                            </div>
                          </div>

                          <!-- <div class="row mb-3">
                            <label for="Email" class="col-md-4 col-lg-3 col-form-label">Email</label>
                            <div class="col-md-8 col-lg-9">
                              <input type="email" class="form-control" v-model="email" id="Email" value="">
                            </div>
                          </div> -->
                        </div>
                      </div>

                      <div class="text-center" v-if="isLoading">
                        <loader />
                      </div>

                      <div v-else class="text-center pt-2">
                        <button type="submit" class="btn btn-generate">Submit</button>
                      </div>
                    </form><!-- End Profile Edit Form -->
                  </div>

                  <!-- <div class="tab-pane fade pt-3" id="profile-settings">
                  </div> -->

                  <!-- Change Password Form -->
                  <div class="tab-pane fade pt-3" id="profile-change-password">
                    <div class="alert alert-danger p-1 mx-4 br-radius text-center" v-if="passwordError"> {{ message }}</div>
                    <form @submit.prevent="changePasswordFN">
                      <div class="row mb-3">
                        <label for="currentPassword" class="col-md-4 col-lg-3 col-form-label">Current Password</label>
                        <div class="col-md-8 col-lg-9 face-fram">
                          <div v-if="showPassword == 'password'" class="position_changepass"
                            @click.prevent="methodShowPass">
                            <i class="fa-regular fa-eye-slash"></i>
                          </div>
                          <div v-else class="position_changepass" @click.prevent="methodShowPass">
                            <i class="fa-regular fa-eye"></i>
                          </div>
                          <input name="password" placeholder="Old password" :type="showPassword" 
                          v-model="currentPassword" class="form-control" id="currentPassword">
                          <div class="error-feedback red_color" v-if="V$.currentPassword.$error">
                            {{ V$.currentPassword.$errors[0].$message }}
                          </div>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <label for="newPassword" class="col-md-4 col-lg-3 col-form-label">New Password</label>
                        <div class="col-md-8 col-lg-9 face-fram">
                          <div v-if="toggeType == 'password'" class="position_changepass"
                            @click.prevent="methodPassword">
                            <i class="fa-regular fa-eye-slash"></i>
                          </div>
                          <div v-else class="position_changepass" @click.prevent="methodPassword">
                            <i class="fa-regular fa-eye"></i>
                          </div>
                          <input :type="toggeType" placeholder="New password" v-model="newPassword" class="form-control" id="newPassword">
                          <div class="error-feedback red_color" v-if="V$.newPassword.$error">
                            {{ V$.newPassword.$errors[0].$message }}
                          </div>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <label for="renewPassword" class="col-md-4 col-lg-3 col-form-label">Re-New Password</label>
                        <div class="col-md-8 col-lg-9 face-fram">
                          <div v-if="toggelType == 'password'" class="position_changepass"
                            @click.prevent="toggelPasswordFN">
                            <i class="fa-regular fa-eye-slash"></i>
                          </div>
                          <div v-else class="position_changepass" @click.prevent="toggelPasswordFN">
                            <i class="fa-regular fa-eye"></i>
                          </div>
                          <input name="renewpassword" placeholder="Confirm new password" 
                          :type="toggelType" v-model="repeatPassword" class="form-control" id="renewPassword">
                          <div class="error-feedback red_color" v-if="V$.repeatPassword.$error">
                            {{ V$.repeatPassword.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="text-center" v-if="loader">
                        <loader />
                      </div>
                      <div v-else class="text-center">
                        <button type="submit" class="btn btn-generate">Change Password</button>
                      </div>
                    </form>
                  </div><!-- End Change Password Form -->
                </div><!-- End Bordered Tabs -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </main><!-- End #main -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalErrorHandler from "@/globalErrorHandler";
import { getData_User } from '@/apis';
import { updata_User_Profile } from '@/apis';
import { user_Change_Password } from '@/apis';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: 'profile',
  data() {
    return {
      hasError: false,
      message: '',
      loader: false,
      newPassword: '',
      currentPassword: '',
      repeatPassword: '',
      showPassword: 'password',
      toggeType: 'password',
      toggelType: 'password',
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      updateError: false,
      isLoading: false,
      loading: false,
      firstNameList: "",
      lastNameList: "",
      mobileList: "",
      emailList: "",
      passwordError: false,
      V$: useVuelidate(),
    }
  },
  validations() {
    return {
      currentPassword: { required },
      newPassword: { required },
      repeatPassword: { required },
    }
  },
  computed: {
    ...mapGetters(['GET_DATA_USER'])
  },
  methods: {
    async getData() {
      this.loading = true;
      this.hasError = false;
      try {
        let responseData = await getData_User();
          this.firstNameList = responseData.data.first_name
          this.lastNameList = responseData.data.last_name;
          this.mobileList = responseData.data.mobile;
          this.emailList = responseData.data.email;
          this.firstName = responseData.data.first_name;
          this.lastName = responseData.data.last_name;
          this.mobile = responseData.data.mobile;
          this.email = responseData.data.email;
      }catch (err) {
        console.log(err);
        this.loading = false;
        this.message = globalErrorHandler(err)[0];
        this.hasError = true;
      }finally {
        this.loading = false;
      }
    },

    async updateProfile() {
      this.isLoading = true;
      this.updateError = false;
      try {
        await updata_User_Profile(this.firstName,this.lastName,this.mobile,this.email);
        document.getElementById('Profile_Page').click();
        this.getData();
      }catch(err) {
        this.isLoading = false;
        this.message = globalErrorHandler(err)[0];
        this.updateError = true;
        window.scroll(0,0);
      }finally {
        this.isLoading = false;
      }
    },

    async changePasswordFN() {
      this.loader = true,
      this.passwordError = false;
      this.V$.$validate();
      try {
        await user_Change_Password(this.currentPassword,this.newPassword,this.repeatPassword);
        this.$store.commit('REMOVE_USER_DATA');
        this.$router.push({ path: '/login' });
      }catch (err) {
        this.loader = false;
        this.message = globalErrorHandler(err)[0];
        this.passwordError = true;
        window.scroll(0,0);
      }finally {
        this.loader = false;
      }
    },

    methodShowPass() {
      this.showPassword = this.showPassword == 'password' ? 'text' : 'password';
    },

    methodPassword() {
      this.toggeType = this.toggeType == 'password' ? 'text' : 'password';
    },

    toggelPasswordFN() {
      this.toggelType = this.toggelType == 'password' ? 'text' : 'password';
    },
  },
  created() {
    this.getData();
  }
}
</script>