<template>
  <!-- Begin: Home -->
  <div class="home p-3 ">
    <div class="col  text-center">
      <div v-if="hasError" class="alert alert-danger p-2"> {{ message }} </div>
    </div>
    <!-- Recent Designs Section -->
    <section class="card shadow rounded-5  pe-0 ps-1 pt-0 pb-0 hborder">

      <div class="card-body">
        <div class="pb-4 row align-items-start align-items-md-center justify-content-between">
          <div class="col-12 col-md-auto space">
            <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center">
              <div>
                <div class="brand-title">RECENT DESIGNS</div>
                <div class="bg-line"></div>
              </div>
              <div class="mt-2 mt-md-0 ms-md-3">
                <router-link to="/cbrand">
                  <button class="btn btn-bg bg-button-h ps-4 pe-4 pt-1 pb-1 btn-bg btn-sm">Create Brand</button>
                </router-link>
              </div>
            </div>
          </div>
          <!-- Add the brand filter dropdown -->
          <div class="col-12 col-md-auto mt-3 mt-md-0">
            <select v-model="selectedBrand" @change="applyBrandFilter" class="form-select">
              <option value="">All Brands</option>
              <option v-for="brand in brands" :key="brand.id || brand.name" :value="brand.id || brand.name">
                {{ brand.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- Loader -->
        <div v-if="loading" class="row justify-content-center my-5">
          <div class="col text-center">
            <loadersoda />
          </div>
        </div>
        <!-- If not loading -->
        <div v-else>
          <!-- If rows is empty, show the image -->
          <div v-if="rows.length === 0" class="row justify-content-center my-5">
            <div class="col-12 text-center">
              <router-link to="/cbrand">
                <img src="/assets/images/RECENT-DESIGNS-1600-1200.png" alt="No Designs"
                  class="img-fluid no-designs-image" />
              </router-link>
            </div>
          </div>
          <!-- Else, show design cards -->
          <div v-else class="row">
            <div v-for="(row, index) in rows" :key="index" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
              <router-link :to="'/updesign/' + row.id" class="design-card-link">
                <div class="card design-card">
                  <div class="card-img-container">
                    <!-- Lazy Load Image -->
                    <img v-lazy="row.final_image" src="" :style="{
                      backgroundColor: '#F3F3F3',
                      marginTop: '0px !important',
                      marginBottom: '0px !important',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      borderRadius: '0px !important',
                    }" class="card-img br-radius" alt="Design Image" />
                  </div>
                  <!-- Add the decorative line below the image -->
                  <div class="card-divider"></div>
                  <div class="card-body text-center">
                    <h5 class="card-title">{{ row.brand_name }}</h5>
                    <p class="card-text">{{ row.title }}</p>
                    <p class="card-text small text-muted">{{ row.design_size }}</p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>

          <!-- Infinite Scroll Loading Spinner -->
          <div v-if="loadMore" class="row justify-content-center my-4">
            <div class="col text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading more designs...</span>
              </div>
            </div>
          </div>
        </div>



      </div>
    </section>
  </div>

  <!-- End: Home -->
</template>

<script>
import { mapGetters } from 'vuex';
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: 'HomeView',
  data() {
    return {
      hasError: false,
      message: "",
      rows: [],
      brands: [],
      selectedBrand: '',
      loading: false,
      nextPage: '',
      loadMore: false,
    };
  },
  computed: {
    ...mapGetters(["BASE_URL", 'GET_USER', 'DATA_LOADING']),
  },
  methods: {
    getPostList() {
      this.loading = true;
      const brandQuery = this.selectedBrand ? `?brand=${this.selectedBrand}` : '';
      this.$http.get(`/api/templates/list/${brandQuery}`).then(res => {
        this.loading = false;
        this.rows = res.data.results;
        this.nextPage = res.data.next;
        this.extractBrandsFromTemplates(res.data.results); // Extract brands from the response
      }).catch(err => {
        this.hasError = true;
        this.message = globalErrorHandler(err.data)[0];
        this.loading = false;
      });
    },

    // Load more posts for infinite scroll
    loadMorePosts() {
      if (this.loadMore || !this.nextPage) return;

      this.loadMore = true;
      this.$http.get(this.nextPage).then(res => {
        this.rows.push(...res.data.results);
        this.nextPage = res.data.next;
        this.extractBrandsFromTemplates(res.data.results); // Extract brands from the new data
        this.loadMore = false;
      }).catch(err => {
        this.hasError = true;
        this.message = globalErrorHandler(err.data)[0];
        this.loadMore = false;
      });
    },
    // Extract unique brands from the templates response
    extractBrandsFromTemplates(templates) {
      const brandsSet = new Set(this.brands.map(brand => brand.name)); // Prevent duplicates

      templates.forEach(template => {
        if (template.brand_name && !brandsSet.has(template.brand_name)) {
          brandsSet.add(template.brand_name);
          this.brands.push({ id: null, name: template.brand_name }); // Add to brands list
        }
      });
    },


    // Fetch brands from subscriptions
    fetchBrandsFromSubscriptions() {
      this.$http.get('/api/subscriptions/brands/').then(res => {
        res.data.brands.forEach(brand => {
          if (!this.brands.some(b => b.id === brand.id)) {
            this.brands.push({ id: brand.id, name: brand.name }); // Add brand with id
          }
        });
      }).catch(err => {
        this.hasError = true;
        this.message = globalErrorHandler(err.data)[0];
      });
    },

    // Apply brand filter
    applyBrandFilter() {
      this.rows = [];
      this.nextPage = '';
      this.getPostList(); // Fetch new data based on selected brand
    },


    handleError(err) {
      if (err.response?.data?.error) {
        this.message = err.response.data.error;
      }
      if (err.response?.data?.errors) {
        let key = Object.keys(err.response.data.errors)[0];
        this.message = err.response.data.errors[key][0];
      }
    },
    onScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const pageHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      // Load more if scrolled to bottom
      if (scrollPosition >= pageHeight - 10) {  // Add small offset to avoid precision issues
        this.loadMorePosts();
      }
    }
  },
  created() {
    if (!this.GET_USER && !this.DATA_LOADING) {
      this.$router.push({ path: '/login' });
    }
    this.getPostList();
    this.fetchBrandsFromSubscriptions();
    window.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>
<style scoped>
.no-designs-image {
  max-width: 50%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .no-designs-image {
    max-width: 100%;
    /* Ensure it fits on mobile screens */
  }
}

/* Sticky Header */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid #dee2e6;
}

/* Section Titles */
.section-title {
  font-size: 1.8rem;
  font-weight: bold;
}

.section-subtitle {
  color: #6c757d;
  margin-top: 0.5rem;
}

/* Design Cards */

/* Base Styles (for large screens and desktops) */
.design-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  min-height: 400px;

}

.card-img-container {
  width: 100%;
  height: 251px;
  overflow: hidden;
}

/* Extra Large Screens (from 1800px and up) */
@media (min-width: 1800px) {
  .design-card {
    min-height: 400px;
  }

  .card-img-container {
    height: 304px;
  }
}

/* Large Screens (desktops and larger screens) */
@media (min-width: 1200px) and (max-width: 1799px) {
  .design-card {
    min-height: 365px;
  }

  .card-img-container {
    height: 210px;
  }
}

/* Medium screens (laptops and tablets in landscape mode) */
@media (min-width: 992px) and (max-width: 1199px) {
  .design-card {
    min-height: 463px;
  }

  .card-img-container {
    height: 298px;
  }
}

/* Tablets (portrait mode) */
@media (min-width: 768px) and (max-width: 991px) {
  .design-card {
    min-height: 513px;
  }

  .card-img-container {
    height: 348px;
  }
}

/* Small screens (mobile devices) */
@media (max-width: 767px) {
  .design-card {
    min-height: 500px;
  }

  .card-img-container {
    height: 350px;
  }
}

/* Extra small screens (small mobile devices) */
@media (max-width: 480px) {
  .design-card {
    min-height: 417px;
  }

  .card-img-container {
    height: 270px;
  }
}


.design-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}

.card-img {
  width: 100%;
  height: 100%;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-right: 6px !important;
  padding-left: 6px !important;
  object-fit: contain;
}

.card-divider {
  height: 3px;
  background-image: linear-gradient(90deg, #e26600 0%, #f2295b 100%);
  margin-top: 0px;
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.brand-title {
  font-size: 1.5rem;
  font-weight: bold;
}

/* For larger screens like desktops */
@media (min-width: 1024px) {
  .brand-title {
    font-size: 1.5rem;
  }
}

/* For tablets and medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
  .brand-title {
    font-size: 1.25rem;
  }
}

/* For smaller screens like phones */
@media (max-width: 767px) {
  .brand-title {
    font-size: 1rem;
    text-align: center;
  }

  .btn-bg {
    width: 100%;
    /* Full width button on mobile */
  }

  .form-select {
    width: 100%;
    /* Full width dropdown on mobile */
  }
}


/* Infinite Scroll Loading Spinner */
.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-top: 2rem;
}

.bgcustom {
  background-color: #ffffff;

}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.hborder {
  border-width: 3px !important;
  border-color: #EDEBEBFF !important;

}
</style>
