<!-- NotificationPopup.vue -->
<template>
  <transition name="fade">
    <div v-if="visible" class="notification-popup">
      <img :src="iconSrc" alt="Icon" class="notification-icon" />
      <p class="notification-message">{{ message }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    iconSrc: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 3000, 
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, this.duration);
    },
  },
  mounted() {
    this.show();
  },
};
</script>

<style scoped>
.notification-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  z-index: 1000;
}

.notification-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

.notification-message {
  font-size: 1rem;
  color: #333;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
