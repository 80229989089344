<template>
  <div v-if="errors.length" class="alert alert-danger mx-4 text-center p-1 br-radius">
    <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
  </div>

  <form id="setup-form" @submit.prevent="setupPaymentMethod">
    <div id="payment-element"><!-- Stripe.js will insert the Payment Element here --></div>
    <button id="submit-button" type="submit">
      Submit
    </button>
    <div class="text-center" v-if="isLoading">
      <loader />
    </div>
  </form>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { setupIntent as serverSetupIntent, addPaymentMethod } from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";
import { mapGetters } from 'vuex';

export default {
  name: "PaymentForm",
  props: {
    thenPromising: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      stripe: null,
      isLoading: false,
      elements: null,
      paymentElement: null,
      clientSecret: null,
      messages: [],
      publicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      errors: [],
    };
  },
  async mounted() {
    // Initialize Stripe with your public key
    this.stripe = await loadStripe(this.publicKey);

    // Check if redirected with setup_intent params
    const urlParams = new URLSearchParams(window.location.search);
    const setupIntentId = urlParams.get("setup_intent");
    const clientSecret = urlParams.get("setup_intent_client_secret");

    if (setupIntentId && clientSecret) {
      // Use the client secret to retrieve and handle the setup intent
      await this.handleRedirectedSetupIntent(clientSecret);
    } else {
      const response = await serverSetupIntent();
      this.clientSecret = response.client_secret;

      const appearance = { theme: 'stripe' };

      this.elements = this.stripe.elements({ clientSecret: this.clientSecret, appearance });

      // Mount the PaymentElement to the DOM
      this.paymentElement = this.elements.create("payment");
      this.paymentElement.mount("#payment-element");
    }
  },
  computed: {
    ...mapGetters(['GET_DATA_USER'])
  },
  methods: {
    formatErrorMessages(error) {
      const messages = [];

      if (typeof error === 'string') {
        messages.push(error);
      } else if (error.message) {
        // Add the main message
        messages.push(error.message);

        // Additional details if available
        if (error.code) messages.push(`Code: ${error.code}`);
        if (error.decline_code) messages.push(`Decline Reason: ${error.decline_code}`);
        if (error.param) messages.push(`Parameter: ${error.param}`);
      } else {
        messages.push("An unknown error occurred.");
      }
      this.isLoading = false;
      return messages;
    },
    async setupPaymentMethod() {
      try {
        this.isLoading = true;

        const { setupIntent, error } = await this.stripe.confirmSetup({
          elements: this.elements,
          confirmParams: {
            return_url: window.location.href,
            payment_method_data: {
              billing_details: {
                email: this.$store.getters.GET_DATA_USER.email,
              },
            },
          },
          redirect: 'if_required'
        });

        if (error) {
          // Manually format error messages before setting `this.errors`
          this.errors = globalErrorHandler(error);
          // Reset the form and setup intent
          await this.resetForm();

        } else if (setupIntent.status === 'succeeded') {
          const paymentMethodId = setupIntent.payment_method;
          try {
            await addPaymentMethod({
              payment_method_id: paymentMethodId,
            });
            this.thenPromising();

            setTimeout(() => {
              this.isLoading = false;
              this.$emit("close");

            }, 200);
          } catch (addMethodError) {
            this.errors = globalErrorHandler(addMethodError);
            // Reset the form and setup intent
            await this.resetForm();
          }
        } else {
          this.errors = [`SetupIntent status: ${setupIntent.status}`];
          await this.resetForm();
        }
      } catch (generalError) {
        this.errors = globalErrorHandler(generalError);
        // Reset the form and setup intent
        await this.resetForm();
      }
    },
    async handleRedirectedSetupIntent(clientSecret) {
      try {
        // Retrieve the setup intent using the client secret
        const { setupIntent } = await this.stripe.retrieveSetupIntent(clientSecret);

        if (setupIntent.status === 'succeeded') {
          const paymentMethodId = setupIntent.payment_method;
          try {
            await addPaymentMethod({
              payment_method_id: paymentMethodId,
            });
            this.thenPromising();
            // Clear URL parameters
            const url = new URL(window.location);
            url.searchParams.delete('setup_intent');
            url.searchParams.delete('setup_intent_client_secret');
            window.history.replaceState({}, document.title, url.pathname);
          } catch (error) {
            this.errors = globalErrorHandler(error);
            // Reset the form and setup intent
            await this.resetForm();
          }
        } else {
          // Handle unsuccessful statuses
          this.errors = [`SetupIntent status: ${setupIntent.status}`];
          // Reset the form and setup intent
          await this.resetForm();
        }
      } catch (error) {
        this.errors = globalErrorHandler(error);
        // Reset the form and setup intent
        await this.resetForm();
      }
    },
    async resetForm() {
      // Unmount the existing payment element if it exists
      if (this.paymentElement) {
        this.paymentElement.unmount();
      }

      // Reset errors and loading state
      this.errors = [];
      this.isLoading = false;

      // Create a new setup intent and initialize payment element
      await this.initializePaymentElement();
    },
    async initializePaymentElement() {
      try {
        const response = await serverSetupIntent();
        this.clientSecret = response.client_secret;
        const appearance = { theme: 'stripe' };
        this.elements = this.stripe.elements({ clientSecret: this.clientSecret, appearance });
        this.paymentElement = this.elements.create("payment");
        this.paymentElement.mount("#payment-element");
      } catch (error) {
        this.errors = globalErrorHandler(error);
      }
    },
  },
};
</script>

<style scoped>
/* Payment Element Container */
#payment-element {
  padding: 10px 12px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: #f9fafb;
  margin-bottom: 20px;
  transition: border-color 0.2s ease-in-out;
}

#payment-element:focus {
  border-color: #6772e5;
  outline: none;
}

/* Submit Button Styling */
#submit-button {
  background-color: #6772e5;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

#submit-button:hover {
  background-color: #5469d4;
}
</style>