<template>
  <div class="login-page-main">
    <div class="container-fluid bg-bubbles ps-0 login-background">
      <div class="row">
        <!-- Left side - Form Section -->
        <div class="col col-sm-5 login-form min-vh-100">
          <div class="row py-0">
            <div class="col text-center">
              <img src="/assets/images/logo-soda.svg" class="w-75 login-logo" alt="Soda Logo">
            </div>
          </div>

          <!-- Loader -->
          <div class="row pt-1" v-if="loading">
            <div class="col text-center">
              <loadersoda />
            </div>
          </div>

          <!-- Forget Password Form -->
          <div class="row pt-1" v-if="changeF && !loading">
            <div class="col">
              <div v-if="hasError" class="alert alert-danger p-2 text-center">{{ message }}</div>
              <form @submit.prevent="methodForgetPassword">
                <div class="row py-3 mt-3">
                  <div class="col">
                    <strong class="title-login text-center">Forget Password</strong>
                    <h5 class="text-center">Please enter the email address you'd like your password reset code sent to.
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col pb-2">
                    <label class="font-color-g font-bold" for="userName">Email</label>
                    <div class="input-group">
                      <span class="input-group-text custom-icon-bg">
                        <i class="fa fa-envelope custom-icon-color"></i>
                      </span>
                      <input type="email" id="userName" class="form-control login-inputs"
                        placeholder="Enter Your Email Address" v-model="email" />
                    </div>
                  </div>
                </div>
                <div class="row py-4">
                  <div class="col">
                    <button type="submit" class="form-control bg-button-h font-bold">Reset Password</button>
                  </div>
                  <router-link to="/login">
                    <div class="row py-2 mt-3">
                      <div class="col text-center">
                        <div class="p-color font-bold">Back to login</div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </form>
            </div>
          </div>

          <!-- Reset Password Form -->
          <div v-else class="row pt-1">
            <div class="col">
              <div v-if="resetError" class="alert alert-danger p-2 text-center">{{ message }}</div>
              <form @submit.prevent="resetpassFN">
                <div class="row py-3 mt-3">
                  <div class="col">
                    <strong class="title-login text-center">Reset Password</strong>
                    <h5 class="text-center">Please enter a new password and the code you received.</h5>
                  </div>
                </div>

                <!-- New Password Input -->
                <div class="row">
                  <div class="col pb-2">
                    <label class="font-color-g font-bold">New Password</label>
                    <div class="input-group">
                      <span class="input-group-text custom-icon-bg">
                        <i class="fa fa-key custom-icon-color"></i>
                      </span>
                      <input type="password" class="form-control login-inputs" placeholder="New password"
                        v-model="newpassword" />
                    </div>
                  </div>
                </div>

                <!-- Repeat New Password Input -->
                <div class="row">
                  <div class="col pb-2">
                    <label class="font-color-g font-bold">Repeat New Password</label>
                    <div class="input-group">
                      <span class="input-group-text custom-icon-bg">
                        <i class="fa fa-key custom-icon-color"></i>
                      </span>
                      <input type="password" class="form-control login-inputs" placeholder="Repeat new password"
                        v-model="renewpass" />
                    </div>
                  </div>
                </div>

                <!-- Code Input -->
                <div class="row">
                  <div class="col pb-2">
                    <label class="font-color-g font-bold">Code</label>
                    <div class="input-group">
                      <span class="input-group-text custom-icon-bg">
                        <i class="fa fa-lock custom-icon-color"></i>
                      </span>
                      <input type="text" class="form-control login-inputs" placeholder="Enter code received by email"
                        v-model="code" />
                    </div>
                  </div>
                </div>

                <div class="row py-4">
                  <div class="col">
                    <button type="submit" class="form-control bg-button-h font-bold">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!-- Footer Links -->
          <div class="row py-5 d-block d-lg-none">
            <div class="col-12">
              <div class="d-flex justify-content-center flex-nowrap text-muted footer-links overflow-hidden">
                <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Terms of Service</a>
                <span class="mx-1">|</span>
                <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Privacy Policy</a>
                <span class="mx-1">|</span>
                <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Security</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Right side - Text and Image -->
        <div class="col col-sm-7 login-right-content">
          <div class="login-right">
            <div class="row padding-p">
              <div class="col text-center text-white bg-text">
                <h4>AI technology with the flavor of a human touch is like adding soda to your favorite drink.</h4>
                <h5 class="font-10">Create your content and designs for your target audience with minimal effort and the
                  best creativity of artificial intelligence combined with the human touch.</h5>
              </div>
            </div>
            <div class="row">
              <div class="col text-center">
                <img src="/assets/images/slider.png" class="sliderimage" alt="Slider Image">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: "ForgetPassword",
  data() {
    return {
      hasError: false,
      message: '',
      email: "",
      newpassword: "",
      renewpass: "",
      code: "",
      loading: false,
      changeF: true,
      resetError: false,
      loader: false,
    }
  },
  methods: {
    methodForgetPassword() {
      this.loading = true;
      this.$http.post('/api/auth/forgot-password/', { email: this.email })
        .then(res => {
          this.loading = false;
          this.changeF = false;
        })
        .catch(err => {
          this.message = globalErrorHandler(err.data.errors)[0];
          this.hasError = true;
          this.loading = false;
          window.scroll(0, 0);
        });
    },
    resetpassFN() {
      this.loader = true;
      if (this.newpassword === '' || this.renewpass === '' || this.code === '') {
        this.message = 'All fields are required';
        this.resetError = true;
        this.loader = false;
        return;
      }

      this.$http.post('/api/auth/reset-forgot-password/', {
        new_password: this.newpassword,
        re_new_password: this.renewpass,
        code: this.code,
        email: this.email,
      }).then(res => {
        this.$router.push({ path: '/login' });
      }).catch(err => {
        this.message = globalErrorHandler(err.data)[0];
        this.loading = false;
        this.resetError = true;
        this.loader = false;
        window.scroll(0, 0);

      });
    }
  }
}
</script>

<style scoped>
.bgcustom {
  background-color: #f5f5f5;
}

.card {
  background-color: #ffffff;
  border-radius: 17px;
}

.icon-w {
  font-size: 30px !important;
}
</style>
