<template>
  <div class="container-fluid">
    <div class="row pt-4">
      <div class="col-12">
        <!--begin:breadcrumb-->
        <div class="row">
          <div class="col">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Billing</li>
              </ol>
            </nav>
          </div>
        </div>
        <!--end:breadcrumb-->
        <!--begin-content-->
        <div class="row pb-2">
          <div class="col-auto">
            <div class="font-26">Billing</div>
            <div class="bg-line"></div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row pt-3">
              <!--subscription-->
              <Subscription />
              <!-- plans & add-ons-->
              <Plans />
            </div>
          </div>
        </div>
        <!--invoices-->
        <Invoices />
      </div>
    </div>
  </div>
</template>
<script>
import Invoices from "@/views/Billing/Invoices.vue";
import Subscription from "@/views/Billing/Subscription.vue";
import Plans from "@/views/Billing/Plans.vue";

export default {
  name: 'billing',
  components: { Plans, Subscription, Invoices }
}
</script>