<template>
  <div class="form-group p-2">
    <!-- Fill Color Picker -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Fill Color</label>
        <color-picker v-model:pure-color="fillColor" :inline="true"/>
      </div>
    </div>
    <!-- Stroke Color Picker -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Stroke Color</label>
        <color-picker v-model:pure-color="strokeColor" :inline="true"/>
      </div>
    </div>
    <!-- Stroke Width -->
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Stroke Width</label>
        <input type="range" class="form-range" v-model="strokeWidth" min="0" max="20">
        <input type="number" v-model="strokeWidth" class="form-control mt-1">
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, defineEmits} from 'vue';
import {ColorPicker} from 'vue3-colorpicker';

defineOptions({
  name: 'Shape'
});

const props = defineProps({
  element: {
    required: true
  }
});

const emit = defineEmits(['propertyChanged']);

const style = computed(() => getComputedStyle(props.element.node));

const getStyle = (cssAttrName) => {
  const css = props.element.css(cssAttrName);
  if (css) return css;
  else return style.value[cssAttrName];
};

const rgbStringToHex = (rgbString) => {
  const match = rgbString.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
  if (!match) return rgbString;
  const [r, g, b] = match.slice(1).map(Number);
  const toHex = (value) => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const fillColor = computed({
  get() {
    return getStyle('fill') ? rgbStringToHex(getStyle('fill')) : '#000000';
  },
  set(value) {
    const oldValue = getStyle('fill');
    props.element.css('fill', rgbStringToHex(value));
    emit('propertyChanged', {
      element: props.element,
      property: 'css:fill',
      before: oldValue,
      after: rgbStringToHex(value)
    });
  }
});

const strokeColor = computed({
  get() {
    return getStyle('stroke') ? rgbStringToHex(getStyle('stroke')) : '#000000';
  },
  set(value) {
    const oldValue = getStyle('stroke');
    props.element.css('stroke', rgbStringToHex(value));
    emit('propertyChanged', {
      element: props.element,
      property: 'css:stroke',
      before: oldValue,
      after: rgbStringToHex(value)
    });
  }
});


const strokeWidth = computed({
  get() {
    return getStyle('stroke-width') ? rgbStringToHex(getStyle('stroke-width')) : 0;
  },
  set(value) {
    const oldValue = getStyle('stroke-width');
    props.element.css('stroke-width', value);
    emit('propertyChanged', {
      element: props.element,
      property: 'css:stroke-width',
      before: oldValue,
      after: value
    });
  }
});
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: bold;
}

.color-picker {
  width: 100%;
}

input[type="range"] {
  width: 100%;
}
</style>
