<template>
  <div class="container bgcustom pt-0 pb-2 pe-4 ps-4 mt-4">
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0">
        <div class="not-found">
          <img src="/assets/images/404error.svg" class="error-404" alt="404 - Page Not Found">
        </div>
        <div class="card-body mt-1 text-center"><router-link to="/"><button type="button" class="btn btn-primary btn-lg m-t-5">Go back to
            Home <i class="fa fa-home"></i></button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.error-404 {
  width: 67%;
}

.btn-primary {
  background-color: #f60976;
  border-color: #e45c0c;
}

.btn-primary:hover {
  background-color: #e45c0c;
  border-color: #f60976;
}
</style>
