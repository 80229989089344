<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0">
        <div class="row justify-content-center pt-2">
          <div class="col col-sm-6 text-center">
            <div v-if="hasError" class="alert alert-danger p-2">{{ message }}</div>
          </div>
        </div>
        <!-- Navigation Bar -->
        <div class="row py-2">
          <div class="col d-flex align-items-center">
            <div class="back-button" @click="$router.go(-1)">
              <i class="fa-solid fa-circle-arrow-left font-color font-26"></i>
            </div>

            <h2 class="brand-title ms-4">Editor</h2>
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 d-flex flex-row">
              <li class="nav-item mx-2">
                <button class="btn nav-btn" @click="undo" :disabled="undoStack.length === 0">
                  <i class="fa-solid fa-arrow-rotate-left"></i>
                </button>
              </li>
              <li class="nav-item mx-2">
                <button class="btn nav-btn" @click="redo" :disabled="redoStack.length === 0">
                  <i class="fa-solid fa-arrow-rotate-right"></i>
                </button>
              </li>
              <li class="nav-item mx-2">
                <button class="btn nav-btn" @click="zoomOut">
                  <i class="fa-solid fa-magnifying-glass-minus"></i>
                </button>
              </li>
              <li class="nav-item mx-2">
                <button class="btn nav-btn" @click="zoomIn">
                  <i class="fa-solid fa-magnifying-glass-plus"></i>
                </button>
              </li>
              <li class="nav-item mx-2">
                <button class="btn nav-btn reset-btn" @click="reset">Reset</button>
              </li>
              <li class="nav-item mx-2">
                <button class="btn nav-btn" @click="downloadAsPNG">
                  <i class="fa-solid fa-download"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>

        <!-- Main Content -->
        <div class="row">
          <!-- SVG Editor Area -->
          <div class="col-12 col-sm-8 col-xxl-9 pt-3">
            <div id="editor" class="svg-editor"></div>
          </div>
          <!-- Sidebar -->
          <div class="col-12 col-sm-4 col-xxl-3">
            <div class="card side-card">
              <div class="card-body">
                <div v-if="element && canShowProperties">
                  <!-- Back Button -->
                  <div class="mb-3">
                    <button class="btn btn-secondary" @click="hideProperties">
                      <i class="fa-solid fa-arrow-left"></i> Back
                    </button>
                  </div>
                  <!-- Element Properties -->
                  <div v-if="element.type === 'text'">
                    <h6 class="card-title pt-3">Text Properties</h6>
                    <Text :element="element" @propertyChanged="onPropertyChanged"/>
                  </div>
                  <div v-else-if="element.type === 'image'">
                    <h6 class="card-title pt-3">Image Properties</h6>
                    <ImageComponent :element="element" @propertyChanged="onPropertyChanged"/>
                  </div>
                  <div v-else-if="element.type === 'rect' || element.type === 'path'">
                    <h6 class="card-title pt-3">{{ element.type.charAt(0).toUpperCase() + element.type.slice(1) }}
                      Properties</h6>
                    <Shape :element="element" @propertyChanged="onPropertyChanged"/>
                  </div>

                </div>

                <div v-else>
                  <!-- Layers -->
                  <div class="card layers-card">
                    <div class="card-header d-flex align-items-center justify-content-between">
                      <h5 class="card-title">Layers</h5>
                      <a href="javascript:void(0)" @click="unselectElement">
                        <i class="fa-regular fa-circle-xmark fs-3"></i>
                      </a>
                    </div>
                    <div class="card-body p-0">
                      <div class="layers-list">
                        <ul class="list-group">
                          <li :class="['list-group-item', { 'active': element === child }]"
                              v-for="(child, index) in elements" :key="index" @click="selectElement(child)">
                            <div class="d-flex align-items-center justify-content-between">
                              <span>{{ getElementName(child) }}</span>
                              <div class="d-flex align-items-center">
                                <button class="btn action-btn lock-btn" @click.stop="toggleLock(child)">
                                  <i
                                    :class="lockedElements[child.id()] ? 'fa-solid fa-lock' : 'fa-solid fa-lock-open'"></i>


                                </button>
                                <div class="dropdown">
                                  <button class="btn dropdown-toggle action-btn" type="button" data-bs-toggle="dropdown"
                                          aria-expanded="false" @click.stop>
                                    <i class="fa-solid fa-ellipsis-v"></i>
                                  </button>
                                  <ul class="dropdown-menu dropdown-menu-end">
                                    <li><a class="dropdown-item" href="#"
                                           @click.prevent.stop="forward(child)">Forward</a>
                                    </li>
                                    <li><a class="dropdown-item" href="#"
                                           @click.prevent.stop="backward(child)">Backward</a>
                                    </li>
                                    <li><a class="dropdown-item" href="#" @click.prevent.stop="front(child)">Front</a>
                                    </li>
                                    <li><a class="dropdown-item" href="#" @click.prevent.stop="back(child)">Back</a>
                                    </li>
                                    <li><a class="dropdown-item" href="#" @click.prevent.stop="remove(child)">Remove</a>
                                    </li>
                                    <li><a class="dropdown-item" href="#"
                                           @click.prevent.stop="duplicate(child)">Duplicate</a>
                                    </li>
                                    <li><a class="dropdown-item" href="javascript:void(0)"
                                           @click.prevent.stop="showProperties">Properties</a>
                                    </li>
                                  </ul>

                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <!-- Add Elements -->
                  <div class="mt-4">
                    <button class="btn btn-primary w-100 mb-2" @click="addTextElement">Add Text</button>
                    <button class="btn btn-primary w-100" @click="addImageElement">Add Image</button>
                    <!-- Image Upload -->
                    <div class="upload-area mt-3">
                      <input type="file" @change="uploadImage($event)" accept=".png, .jpg, .jpeg" class="form-control">
                      <div class="upload-text">
                        <i class="fa-solid fa-cloud-arrow-up me-2"></i>
                        Drag an image here or click to choose a file.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Brand Logo -->
                <div class="row">
                  <div class="col" style="position:relative;">
                    <img src="/assets/images/Vector.png" class="logo_Style" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, computed, watch, reactive} from 'vue';
import {useStore} from 'vuex';
import {retrieveSvgContent} from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";
import Text from "@/views/Editor/Text";
import ImageComponent from "@/views/Editor/Image";
import Shape from "@/views/Editor/Shape";
import {Canvg} from 'canvg';

// SVG.js and plugins
import {SVG} from '@svgdotjs/svg.js';
import '@svgdotjs/svg.draggable.js';
import '@svgdotjs/svg.select.js';
import '@svgdotjs/svg.resize.js';
import '@svgdotjs/svg.panzoom.js';

// HTML to PNG
import * as htmlToImage from "html-to-image";

// Define the component name
defineOptions({
  name: 'SVGEditor'
});

// Define props
const props = defineProps({
  svg_path: {
    type: String,
    required: true
  }
});

// Reactive error variables
const hasError = ref(false);
const message = ref('');


const getElementName = (child) => {
  if (child.type === 'text') {
    const content = child.text().length > 10 ? child.text().substring(0, 10) + '...' : child.text();
    return `Text (${content})`;
  } else if (child.type === 'image') {
    return 'Image';
  } else if (child.type === 'rect') {
    return 'Rectangle';
  } else if (child.type === 'path') {
    return 'Path';
  } else {
    return child.type.charAt(0).toUpperCase() + child.type.slice(1);
  }
};
// Access Vuex store
const store = useStore();

// Define the computed getter directly
const baseSubscription = computed(() => store.getters.GET_BASE_SUBSCRIPTION);

// Define reactive variables
const loading = ref(true);
const errors = ref([]);
const draw = ref(SVG());
const excludedTags = ref(['defs', 'style']);
const element = ref(null);
const elements = ref([]);
const undoStack = ref([]);
const redoStack = ref([]);
const zoomLevel = ref(0.2);
const initialViewBox = ref('0 0 1080 1080');
const initialWidth = ref(1080);
const initialHeight = ref(1080);
const canShowProperties = ref(false);
const zoomStep = 0.1;
const minZoom = 0.1;
const maxZoom = 2;
const lockedElements = reactive({});

const toggleLock = (child) => {
  const elementId = child.id();
  const isLocked = lockedElements[elementId] || false;

  if (isLocked) {
    // Unlock the element
    lockedElements[elementId] = false;
    child.css('pointer-events', 'auto');

    // Reattach event listeners
    child.draggable();
    child.resize();
    child.on('click', () => {
      selectElement(child);
    });
  } else {
    // Lock the element
    lockedElements[elementId] = true;
    child.css('pointer-events', 'none');

    // Remove event listeners
    child.draggable(false);
    child.resize('stop');
    child.off('click');
  }
};


// Variables to track resizing
let isResizing = false;
let resizeStartState = null;
let resizeTimer = null;
const RESIZE_DEBOUNCE_TIME = 200; // milliseconds

// Variables to track dragging
let isDragging = false;
let dragStartState = null;
let dragTimer = null;
const DRAG_DEBOUNCE_TIME = 200; // milliseconds

const initiate = async () => {
  try {
    const parser = new DOMParser();
    const response = await retrieveSvgContent(props.svg_path);
    const svgDocument = parser.parseFromString(response, "image/svg+xml");
    const svgElement = svgDocument.querySelector("svg");
    const viewBox = svgElement.getAttribute('viewBox') || '0 0 1080 1080';
    initialViewBox.value = viewBox;
    // Update svgBase and append the SVG element to the DOM
    const width = Number(svgElement.getAttribute('width')) || 1080;
    const height = Number(svgElement.getAttribute('height')) || 1080;
    initialWidth.value = width;
    initialHeight.value = height;

    const child = SVG().size(`${width}`, `${height}`).viewbox(viewBox);
    child.svg(svgElement.innerHTML);
    child.panZoom({
      wheelZoom: false,
      zoomMin: 0.1,
      zoomMax: 2.0
    });
    child.addTo('#editor');
    draw.value = child;
    loading.value = false;
  } catch (error) {
    hasError.value = true;
    message.value = globalErrorHandler(error.data)[0];
    window.scroll(0, 0);
  }
};

const selectElement = (child) => {
  const elementId = child.id();
  if (lockedElements[elementId]) {
    // Do not select locked elements
    return;
  }
  element.value = child;
  canShowProperties.value = false;
};


const unselectElement = () => {
  if (element.value) {
    element.value.select(false).resize(false).draggable(false);
    element.value.off('resized');
    element.value.off('dragend');
  }
  element.value = null;
  canShowProperties.value = false;
};

const back = (child) => {
  const index = elements.value.indexOf(child);
  undoStack.value.push({
    type: 'back',
    element: child,
    beforeIndex: index,
    afterIndex: 0,
    parent: child.parent()
  });
  redoStack.value = [];
  child.back();
};

const front = (child) => {
  const index = elements.value.indexOf(child);
  undoStack.value.push({
    type: 'front',
    element: child,
    beforeIndex: index,
    afterIndex: elements.value.length - 1,
    parent: child.parent()
  });
  redoStack.value = [];
  child.front();
};

const forward = (child) => {
  const index = elements.value.indexOf(child);
  undoStack.value.push({
    type: 'forward',
    element: child,
    beforeIndex: index,
    afterIndex: index + 1,
    parent: child.parent()
  });
  redoStack.value = [];
  child.forward();
};

const backward = (child) => {
  const index = elements.value.indexOf(child);
  undoStack.value.push({
    type: 'backward',
    element: child,
    beforeIndex: index,
    afterIndex: index - 1,
    parent: child.parent()
  });
  redoStack.value = [];
  child.backward();
};

const remove = (child) => {
  // Unselect the element if it's selected
  if (element.value === child) {
    unselectElement();
  } else {
    // Ensure all event listeners are removed
    child.select(false).resize(false).draggable(false);
    child.off('resized');
    child.off('dragend');
  }

  undoStack.value.push({
    type: 'remove',
    element: child,
    index: elements.value.indexOf(child),
    parent: child.parent()
  });
  redoStack.value = [];
  elements.value = elements.value.filter(item => item !== child);
  child.remove();
};


const duplicate = (child) => {
  const clone = child.clone(true);
  clone.id(child.id() + '_copy');
  clone.addTo(draw.value);
  elements.value.push(clone);
  undoStack.value.push({
    type: 'duplicate',
    element: clone,
    parent: draw.value
  });
  redoStack.value = [];
};

const showProperties = () => {
  canShowProperties.value = true;
}

const hideProperties = () => {
  canShowProperties.value = false;
}

// Undo function
const undo = () => {
  const action = undoStack.value.pop();
  if (!action) return;

  switch (action.type) {
    case 'resize':
      action.element.size(action.before.width, action.before.height);
      action.element.move(action.before.x, action.before.y);
      action.element.transform(action.before.transform);
      break;
    case 'drag':
      action.element.move(action.before.x, action.before.y);
      break;
    case 'add':
      elements.value = elements.value.filter(item => item !== action.element);
      action.element.remove();
      break;
    case 'remove':
      action.parent.add(action.element);
      elements.value.splice(action.index, 0, action.element);
      break;
    case 'duplicate':
      elements.value = elements.value.filter(item => item !== action.element);
      action.element.remove();
      break;
    case 'forward':
    case 'backward':
    case 'front':
    case 'back':
      elements.value.splice(action.afterIndex, 1);
      elements.value.splice(action.beforeIndex, 0, action.element);
      action.parent.add(action.element, action.beforeIndex);
      break;
    case 'propertyChange':
      handlePropertyChange(action, 'before');
      break;
    default:
      console.warn(`Unknown action type: ${action.type}`);
  }

  redoStack.value.push(action);
};

// Redo function
const redo = () => {
  const action = redoStack.value.pop();
  if (!action) return;

  switch (action.type) {
    case 'resize':
      action.element.size(action.after.width, action.after.height);
      action.element.move(action.after.x, action.after.y);
      action.element.transform(action.after.transform);
      break;
    case 'drag':
      action.element.move(action.after.x, action.after.y);
      break;
    case 'add':
      action.parent.add(action.element);
      elements.value.splice(action.index, 0, action.element);
      break;
    case 'remove':
      elements.value = elements.value.filter(item => item !== action.element);
      action.element.remove();
      break;
    case 'duplicate':
      action.parent.add(action.element);
      elements.value.push(action.element);
      break;
    case 'forward':
    case 'backward':
    case 'front':
    case 'back':
      elements.value.splice(action.beforeIndex, 1);
      elements.value.splice(action.afterIndex, 0, action.element);
      action.parent.add(action.element, action.afterIndex);
      break;
    case 'propertyChange':
      handlePropertyChange(action, 'after');
      break;
    default:
      console.warn(`Unknown action type: ${action.type}`);
  }

  undoStack.value.push(action);
};

// Handle property change
const handlePropertyChange = (action, valueKey) => {
  const value = action[valueKey];

  if (action.property.startsWith('css:')) {
    const cssProperty = action.property.substring(4);
    action.element.css(cssProperty, value);
  } else if (action.property.startsWith('attr:')) {
    const attrProperty = action.property.substring(5);
    action.element.css(attrProperty, value);
  } else if (action.property === 'text') {
    action.element.text(value);
  } else {
    action.element.attr(action.property, value);
  }
};

// Reset the SVG canvas
const reset = () => {
  loading.value = true;
  errors.value = [];
  draw.value = SVG();
  excludedTags.value = ['defs', 'style'];
  element.value = null;
  elements.value = [];
  undoStack.value = [];
  redoStack.value = [];
  zoomLevel.value = 0.2;
  Array.from(document.querySelector('#editor').children).forEach((child) => {
    child.remove();
  })
  initiate();
};

// Zoom in
const zoomIn = () => {
  if (zoomLevel.value < maxZoom) {
    zoomLevel.value = Math.min(zoomLevel.value + zoomStep, maxZoom);
    draw.value.zoom(zoomLevel.value);
  }
};

// Zoom out
const zoomOut = () => {
  if (zoomLevel.value > minZoom) {
    zoomLevel.value = Math.max(zoomLevel.value - zoomStep, minZoom);
    draw.value.zoom(zoomLevel.value);
  }
};

// Download as PNG
const downloadAsPNG = async function () {
  const width = Number(draw.value.width()) || 1080;
  const height = Number(draw.value.height()) || 1080;
  const viewbox = draw.value.viewbox();
  const targetElement = element.value;
  unselectElement();
  draw.value.viewbox(initialViewBox.value);
  // Serialize the updated SVG data
  const svgData = draw.value.svg();
  // Create a Blob from the SVG data
  const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
  const url = URL.createObjectURL(svgBlob);
  // Create an Image element
  const img = new Image();
  img.width = width;
  img.height = height;
  img.onload = function () {
    // Create a Canvas element
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    // Draw the SVG image onto the canvas
    ctx.drawImage(img, 0, 0, width, height);

    // Export the canvas as PNG
    const pngData = canvas.toDataURL('image/png');

    // Trigger download
    const downloadLink = document.createElement('a');
    downloadLink.href = pngData;
    downloadLink.download = 'design.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Clean up
    URL.revokeObjectURL(url);
    element.value = targetElement;
    draw.value.viewbox(viewbox);
  };

  // Set the image source to the blob URL
  img.src = url;
};


// Initialize the SVG canvas when the component is mounted
onMounted(() => {
  initiate();
});

// Watch for changes in loading
watch(draw, (newValue) => {
  if (newValue) {
    const children = [];
    try {
      if (initialHeight.value > initialWidth.value) {
        zoomLevel.value = 0.2;
        newValue.size(`${initialHeight.value}`, `${initialWidth.value}`).zoom(zoomLevel.value);
      } else {
        zoomLevel.value = 0.4
        newValue.size(`${initialWidth.value}`, `${initialHeight.value}`).zoom(zoomLevel.value);
      }
    } catch (err) {
      console.log(err);
    }
    newValue.children().forEach((child) => {
      if (!excludedTags.value.includes(child.type)) {
        children.push(child);
        child.on('click', () => {
          selectElement(child);
        });
      }
    });
    elements.value = children;
  }
});

watch(element, (newValue, oldValue) => {
  if (oldValue) {
    oldValue.draggable(false);
    oldValue.select(false).resize(false);
    oldValue.off('resize');
    oldValue.off('dragmove');
  }
  if (newValue) {
    newValue.draggable();
    newValue.select({
      createHandle: (group) => group.rect(30, 30).css({fill: "red"}),
      updateHandle: (shape, p) => shape.center(p[0], p[1]),
      createRot: (group) => {
        group.circle(40).move(0, -120).css({fill: 'red'});
        group.line(0, 80, 0, 0).move(20, 0).stroke({color: 'transparent', width: 2, linecap: 'round'});
        return group;
      },
      updateRot: (shape, p) => shape.center(p[0], p[1]),
    }).resize(true);

    // Resize event listener
    newValue.on('resize', (event) => {
      if (!isResizing) {
        // Resize is starting
        isResizing = true;
        resizeStartState = {
          width: newValue.width(),
          height: newValue.height(),
          x: newValue.x(),
          y: newValue.y(),
          transform: newValue.transform()
        };
      }

      // Clear any existing timer
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }

      // Set a new timer to detect the end of the resize
      resizeTimer = setTimeout(() => {
        // Resize has ended
        isResizing = false;

        const resizeEndState = {
          width: newValue.width(),
          height: newValue.height(),
          x: newValue.x(),
          y: newValue.y(),
          transform: newValue.transform()
        };

        // Push the action onto the undo stack
        undoStack.value.push({
          type: 'resize',
          element: newValue,
          before: resizeStartState,
          after: resizeEndState
        });

        // Clear the redo stack
        redoStack.value = [];

        // Reset the start state
        resizeStartState = null;
        resizeTimer = null;
      }, RESIZE_DEBOUNCE_TIME);
    });

    // Drag event listener
    newValue.on('dragmove', (event) => {
      if (!isDragging) {
        // Drag is starting
        isDragging = true;
        dragStartState = {
          x: newValue.x(),
          y: newValue.y()
        };
      }

      // Clear any existing timer
      if (dragTimer) {
        clearTimeout(dragTimer);
      }

      // Set a new timer to detect the end of the drag
      dragTimer = setTimeout(() => {
        // Drag has ended
        isDragging = false;

        const dragEndState = {
          x: newValue.x(),
          y: newValue.y()
        };

        // Push the action onto the undo stack
        undoStack.value.push({
          type: 'drag',
          element: newValue,
          before: dragStartState,
          after: dragEndState
        });

        // Clear the redo stack
        redoStack.value = [];

        // Reset the start state
        dragStartState = null;
        dragTimer = null;
      }, DRAG_DEBOUNCE_TIME);
    });
  }
});


// Handle property changes from child components
const onPropertyChanged = (change) => {
  undoStack.value.push({
    type: 'propertyChange',
    element: change.element,
    property: change.property,
    before: change.before,
    after: change.after
  });
  redoStack.value = [];
};

// Add Text Element
const addTextElement = () => {
  const textElement = draw.value.text('New Text').move(100, 100);
  textElement.font({size: 50, fill: 'black'});
  undoStack.value.push({
    type: 'add',
    element: textElement,
    index: elements.value.length,
    parent: textElement.parent()
  });
  redoStack.value = [];
  elements.value.push(textElement);
  textElement.on('click', () => {
    selectElement(textElement);
  });
};

// Add Image Element
const addImageElement = () => {
  const placeHolder = `${window.location.origin}/assets/images/place_holder.png`
  const imageElement = draw.value.image(placeHolder);
  imageElement.move(250, 150).size(100, 100);
  undoStack.value.push({
    type: 'add',
    element: imageElement,
    index: elements.value.length,
    parent: imageElement.parent()
  });
  redoStack.value = [];
  elements.value.push(imageElement);
  imageElement.on('click', () => {
    selectElement(imageElement);
  });
};


// Upload Image
const uploadImage = (event) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    const placeHolder = e.target.result;
    const imageElement = draw.value.image(placeHolder);
    imageElement.move(250, 150).size(100, 100);
    undoStack.value.push({
      type: 'add',
      element: imageElement,
      index: elements.value.length,
      parent: imageElement.parent()
    });
    redoStack.value = [];
    elements.value.push(imageElement);
    imageElement.on('click', () => {
      selectElement(imageElement);
    });
  };
  reader.readAsDataURL(file);
  event.target.value = null;
};

</script>
<style scoped>
.container-fluid {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f8f9fa;
}

.back-button {
  cursor: pointer;
}

.brand-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-nav .btn {
  background-color: transparent;
  border: none;
  color: #343a40;
  font-size: 1.2rem;
}

.nav-btn:hover {
  color: #e45c0d;
  /* Brand color */
}

.reset-btn {
  color: #6c757d;
  font-weight: bold;
}

.reset-btn:hover {
  color: #e45c0d;
  /* Brand color */
}

.side-card {
  border: none;
  background-color: #ffffff;
  border-radius: 0.5rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #e45c0d;
  /* Brand color */
}

.layers-card {
  border: none;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.layers-card .card-header {
  background-color: #ffffff;
  border-bottom: none;
}

.layers-list {
  max-height: 370px;
  overflow-y: auto;
}

.list-group-item.active {
  background-color: #f50975;
  /* Brand color */
  color: #ffffff;
}

.action-btn {
  background-color: transparent;
  border: none;
  color: #6c757d;
}

.action-btn:hover {
  color: #e45c0d;
  /* Brand color */
}

.upload-area {
  position: relative;
  border: 2px dashed #ced4da;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
}

.upload-area input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.upload-text {
  color: #6c757d;
}

.svg-editor {
  border: 1px solid #ced4da;
  background-color: #ffffff;
  min-height: 600px;
  max-height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  background-color: #e45c0d;
  /* Brand color */
  border: none;
}

.btn-primary:hover {
  background-color: #f50975;
  /* Brand color */
}

.logo_Style {
  max-width: 100%;
  height: auto;
}

/* Style for the selection bounding rectangle */
.svg_select_boundingRect {
  stroke: #e45c0d !important;
  /* Brand color for border */
  stroke-dasharray: 5, 5;
  fill: none !important;
  pointer-events: none;
}

/* Style for the selection handles */
.svg_select_points {
  fill: #ffffff !important;
  stroke: #f50975 !important;
  /* Brand color for handles */
  stroke-width: 1;
}

.colorbutton {
  background-color: #e45c0d;
}

.g .svg_select_shape {
  fill: none !important;
}

.lock-btn {
  background-color: transparent;
  border: none;
  color: #6c757d;
  margin-right: 5px;
}

.lock-btn:hover {
  color: #e45c0d;
  /* Brand color */
}
</style>
