<template>
  <div class="subscription-summary-card row justify-content-center align-items-center align-content-center">
    <div class="row" v-if="loading">
      <div class="col text-center">
        <loader />
      </div>
    </div>
    <div class="row justify-content-center pt-2">
      <div class="col col-sm-6 text-center">
        <div v-if="hasError" class="alert alert-danger p-2"> {{ message }} </div>
      </div>
    </div>
    <div class="card shadow rounded p-3 mb-3" v-for="(count, type) in subscriptionCounts" :key="type">
      <div class="d-flex align-items-center">
        <div class="doughnut-chart">
          <svg viewBox="0 0 36 36" class="circular-chart">
            <path class="circle-bg" d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831" />
          </svg>
          <div class="chart-count">{{ count }}</div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h5 class="mb-1">{{ type }}</h5>
          <button class="btn btn-outline-primary btn-sm" @click="viewDetails(type)">
            More Info
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listSubscriptions } from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";

export default {
  data() {
    return {
      subscriptions: [],
      hasError: false,
      message: '',
      loading: false
    };
  },
  computed: {
    subscriptionCounts() {
      return this.subscriptions.reduce((acc, sub) => {
        const type = sub.display_name || 'Unknown';
        if (!acc[type]) {
          acc[type] = 0;
        }
        acc[type]++;
        return acc;
      }, {});
    },
  },
  methods: {
    async fetchSubscriptions() {
      this.loading = true;
      try {
        let url = null;
        let response;
        let allSubscriptions = [];
        let maxIterations = 100; // Safeguard to prevent infinite loops
        let iteration = 0;
        do {
          if (!url) {
            // Fetch the first page
            response = await listSubscriptions(1);
          } else {
            // Fetch the next page using the next URL
            response = await listSubscriptions(url);
          }
          allSubscriptions = allSubscriptions.concat(response.results);
          url = response.next;
          iteration++;

          // Break if no results are returned to prevent infinite loops
          if (!response.results || response.results.length === 0) {
            break;
          }

          // Safeguard: Break if iterations exceed maxIterations
          if (iteration >= maxIterations) {
            console.warn('Max iterations reached in fetchSubscriptions');
            break;
          }
        } while (url !== null);

        this.subscriptions = allSubscriptions;
        this.loading = false;
      } catch (error) {
        this.hasError = true;
        this.message = globalErrorHandler(error.data)[0];
        window.scroll(0, 0);
      }
    },
    viewDetails(type) {
      this.$router.push({ name: "SubscriptionList", params: { type } });
    },
    getStrokeDash(count) {
      const maxCount = 100;
      return (count / maxCount) * 100;
    }
  },
  created() {
    this.fetchSubscriptions();
  }
};
</script>

<style scoped>
.subscription-summary-card {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.card {
  width: 100%;
  max-width: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
}

.doughnut-chart {
  position: relative;
  width: 60px;
  height: 60px;
}

.circular-chart {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.circle-bg {
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke: #F70081;
  stroke-width: 3.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.5s;
}

.chart-count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  font-weight: bold;
  color: #343a40;
}

.btn-outline-primary {
  border-color: #E26600;
  color: #E26600;
  font-size: 0.875rem;
}

.btn-outline-primary:hover {
  background-color: #E26600;
  color: #ffffff;
}
</style>
