export default function gtmPlugin(store) {
  store.subscribe((mutation, state) => {
    try {
      let eventName = mutation.type;
      let eventData = {
        ...state,
        event: eventName,
      };

      if (window && window.dataLayer) {
        window.dataLayer.push(eventData);
        
      }
    } catch (error) {
      console.error('Error tracking GTM event:', error);
    }
  });
}
