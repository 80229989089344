<template>
  <div class="form-group p-2">
    <div class="row pt-3">
      <div class="col">
        <label class="form-label">Change Image</label>
        <input type="file" class="form-control" @change="uploadImage($event)" accept=".png, .jpg, .jpeg">
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';

// Define the component name
defineOptions({
  name: 'Image'
});

// Define props
const props = defineProps({
  element: {
    required: true
  }
});

// Define emits
const emit = defineEmits(['propertyChanged']);

const handleXLinkHref = (e) => {
  const oldValue = props.element.attr('xlink:href');
  const newValue = e.target.result;
  props.element.attr('xlink:href', newValue);
  emit('propertyChanged', {
    element: props.element,
    property: 'attr:xlink:href',
    before: oldValue,
    after: newValue
  });
};

const handleHref = (e) => {
  const oldValue = props.element.attr('href');
  const newValue = e.target.result;
  props.element.attr('href', newValue);
  emit('propertyChanged', {
    element: props.element,
    property: 'attr:href',
    before: oldValue,
    after: newValue
  });
};

const uploadImage = (event) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    let oldValue = props.element.attr('xlink:href');
    if (oldValue !== undefined) {
      return handleXLinkHref(e);
    } else {
      return handleHref(e);
    }
  };
  reader.readAsDataURL(file);
  event.target.value = null;
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: bold;
}
</style>
