<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 hborder p-3">
      <div class="card-body pt-0">
        <div class="row justify-content-center pt-2">
          <div class="col col-sm-6 text-center">
            <div v-if="hasError" class="alert alert-danger p-2"> {{ message }} </div>
          </div>
        </div>
        <!-- Search Bar -->
        <div class="row justify-content-between align-items-center mb-4">
          <div class="col-12 col-md-6">
            <h2 class="brand-title">Brands List ({{ rows.length }})</h2>
            <p class="brand-subtitle">See your Current Brands and Create New Brands.</p>
          </div>
          <div class="col-12 col-md-4">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search" v-model="searchQuery">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
          </div>
        </div>
        <!-- Brands Grid -->
        <div class="row g-4">
          <!-- Create a Brand Card -->
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="card brand-card create-brand-card text-center">
              <router-link to="/cbrand" class="create-brand-link">
                <div class="card-body d-flex flex-column justify-content-center align-items-center">
                  <div class="brand-create-icon">
                    <i class="fas fa-plus"></i>
                  </div>
                  <h5 class="card-title">Create a Brand</h5>
                  <p class="card-text">Click here to add an additional brand that you can use to generate Soda Designs.
                  </p>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Brand Cards -->
          <div v-for="(row, index) in filteredBrands" :key="index" class="col-12 col-sm-6 col-lg-4">
            <div class="card brand-card">
              <div class="card-body text-center">
                <!-- Responsive Image Container -->
                <div class="brand-logo-container">
                  <img :src="row.logo" class="brand-logo" alt="Brand Logo">
                </div>
                <h5 class="card-title mt-3">{{ row.name }}</h5>
                <p class="card-text"><span class="stat-number">{{ row.generatedDesigns }}</span> Created Designs</p>
                <!-- Color Display Row -->
                <div v-if="row.primary_color || row.secondary_color"
                  class="color-row d-flex justify-content-center mt-2">
                  <div v-if="row.primary_color" :style="{ backgroundColor: row.primary_color }" class="color-swatch">
                  </div>
                  <div v-if="row.secondary_color" :style="{ backgroundColor: row.secondary_color }"
                    class="color-swatch">
                  </div>
                </div>
                <!-- Icon Buttons Row -->
                <div class="btn-group mt-3" role="group">
                  <router-link :to="'/updatebrand/' + row.id" class="btn btn-light" title="Edit Brand">
                    <i class="fas fa-edit icon-action"></i>
                  </router-link>
                  <button class="btn btn-light" title="Delete Brand" @click.prevent="openDeleteModal(row.id, index)">
                    <i class="fas fa-trash-alt icon-action"></i>
                  </button>
                  <router-link :to="'/brandanalysis/' + row.id" class="btn btn-light" title="Brand Analysis">
                    <i class="fas fa-chart-pie icon-action"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Delete Confirmation Modal -->
        <div class="modal fade" id="deleteBrandModal" tabindex="-1" aria-labelledby="deleteBrandModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteBrandModalLabel">Confirm Delete</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Are you sure you want to delete this brand? This action cannot be undone.
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" @click="confirmDeleteBrand">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderSoda from '@/components/LoaderSoda.vue';
import { mapGetters } from 'vuex';
import globalErrorHandler from "@/globalErrorHandler";


export default {
  name: 'BrandView',
  components: { LoaderSoda },
  data() {
    return {
      brandLoading: false,
      rows: [],
      searchQuery: '',
      hasError: false,
      message: '',
      selectedBrandId: null,
      selectedIndex: null,
      optionsOpen: null,
    };
  },
  computed: {
    ...mapGetters(['BASE_URL']),
    filteredBrands() {
      return this.rows.filter((brand) =>
        brand.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    brandList() {
      this.brandLoading = true;
      this.$http.get("/api/subscriptions/brands/")
        .then(res => {
          this.rows = res.data.brands.map(brand => ({
            ...brand,
            generatedDesigns: 0, // Initialize each brand with 0 designs
          }));

          // Now fetch the generated designs for each brand
          this.rows.forEach((brand, index) => {
            this.fetchAllPagesOfDesigns(brand.id, index); // Recursive function to fetch all designs
          });

          this.brandLoading = false;
        })
        .catch(err => {

          this.hasError = true;
          this.message = globalErrorHandler(err.data)[0];
          this.brandLoading = false;
          window.scroll(0, 0);
        });
    },

    // Recursive function to fetch all pages of designs for a brand
    fetchAllPagesOfDesigns(brandId, index, nextPage = `/api/templates/list/?brand=${brandId}`) {
      this.$http.get(nextPage)
        .then(res => {
          const designsCount = res.data.results.length;
          this.rows[index].generatedDesigns += designsCount; // Increment the design count

          // If there is a next page, recursively fetch it
          if (res.data.next) {
            this.fetchAllPagesOfDesigns(brandId, index, res.data.next);
          }
        })
        .catch(err => {
          this.hasError = true;
          this.message = globalErrorHandler(err.data)[0];
          this.brandLoading = false;
          window.scroll(0, 0);

        });
    },

    openDeleteModal(id, index) {
      this.selectedBrandId = id;
      this.selectedIndex = index;
      const deleteModal = new bootstrap.Modal(document.getElementById('deleteBrandModal'));
      deleteModal.show();
    },
    confirmDeleteBrand() {
      this.deleteBrand(this.selectedBrandId, this.selectedIndex);
    },
    deleteBrand(id, index) {
      this.$http.delete(`/api/subscriptions/brands/delete/${id}/`)
        .then(res => {
          this.rows.splice(index, 1);
          this.selectedBrandId = null;
          this.selectedIndex = null;
          const deleteModal = bootstrap.Modal.getInstance(document.getElementById('deleteBrandModal'));
          deleteModal.hide();
        })
        .catch(err => {
          this.hasError = true;
          this.message = globalErrorHandler(err.data)[0];
          this.selectedBrandId = null;
          this.selectedIndex = null;
          const deleteModal = bootstrap.Modal.getInstance(document.getElementById('deleteBrandModal'));
          deleteModal.hide();
        });
    },
  },
  created() {
    this.brandList();
  }
};
</script>

<style scoped>
.stat-number {
  /* font-size: 2.5rem; */
  font-weight: bold;
  color: #f50a74;
}

.pt-4 {
  padding-top: 1.5rem;
}

.brand-title {
  font-size: 2rem;
  font-weight: bold;
}

.brand-subtitle {
  color: #6c757d;
  margin-top: 0.5rem;
}

.input-group {
  width: 100%;
}

.brand-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 1rem;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-height: 390px;
}

.brand-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.create-brand-card {
  background: #f8f9fa;
  border: 1px dashed #ced4da;
}

.brand-create-icon {
  font-size: 3rem;
  color: #f50a74;
  margin-bottom: 1rem;
}

.create-brand-link {
  text-decoration: none;
  color: inherit;
}

/* Responsive Image Styling */
.brand-logo-container {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.brand-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Color Swatches Row */
.color-row {
  display: flex;
  gap: 10px;
}

.color-swatch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ccc;
}

/* Button Group */
.btn-group .btn {
  border: none;
  background-color: transparent;
  margin: 0 5px;
}

.icon-action {
  font-size: 1.5rem;
  color: #f50a74;
  transition: color 0.3s ease;
}

.icon-action:hover {
  color: #e55a10;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}
</style>