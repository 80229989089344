<template>
  <div class="container-fluid">
    <div class="row py-2">
      <div class="col navbar-collapse d-flex">
        <router-link to="/">
          <div>
            <i class="fa-solid fa-circle-arrow-left font-color font-26"></i>
          </div>
        </router-link>
        <ul class="navbar-nav dirBar ms-auto margin-Left mb-2 mb-lg-0">
          <li class="nav-item mx-2 button-center" @click="undo">
            <i class="fa-solid fa-arrow-rotate-left font-color rotateStyle"></i>
          </li>
          <li class="nav-item mx-2 button-center" @click="redo">
            <i class="fa-solid fa-arrow-rotate-right font-color rotateStyle"></i>
          </li>
          <!-- <li class="nav-item mx-2 button-center">
            <input type="text" disabled>
          </li> -->
          <li id="zoomMinus" class="nav-item mx-2 button-center">
            <button id="zoomMinus" class="btn btn-sm">
              <i class="fa-solid fa-magnifying-glass-minus font-color font-20"></i>
            </button>
          </li>
          <li class="nav-item mx-2 button-center">
            <button id="zoomPlus" class="btn btn-sm" >
              <i class="fa-solid fa-magnifying-glass-plus font-color font-20"></i>
            </button>
          </li>
          <li class="nav-item mx-2 button-h">
            <button class="btn button_Style resetStyle" @click="resetButton">Reset</button>
          </li>
          <li class="nav-item mx-2 button-center" @click="convertSVG">
            <a href="" download="image.png" id="link">
              <i class="fa-solid fa-circle-arrow-down font-color font-26"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div v-if="menuBar == 1" class="col-12 col-sm-4 col-xxl-3">
        <div class="card">
          <div class="card-body">
            <div v-if="currentTagName">
              <div>
                <div class="row pt-3">
                  <div class="col col-sm-3 pe-0 text-center button-h">
                    <button :class="(rotation)? 'btn button_Style button-active' : 'btn button_Style' " id="btnRotate" @click="rotatex">
                      <i class="fa-solid fa-arrows-rotate"></i>
                    </button>
                  </div>
                  <div class="col col-sm-2 text-start p-0 button-h">
                    <button :class="(dragButton)? 'btn button_Style button-active':'btn button_Style' " @click="generateDrag">
                      <i class="fa-solid fa-maximize"></i>
                    </button>
                  </div>
                  <div class="col col-sm-2 text-start p-0 button-h">
                    <button :class="(is_Resize)? 'btn button_Style button-active':'btn button_Style' " @click="getResize">
                      <i class="fa-solid fa-minimize"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <h6 class="card-title font-color pt-3">Text Properites</h6>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col">
                  <input type="text" class="form-control" v-model="valueText" @change="addText">
                </div>
              </div> -->
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col d-flex">
                      <div class="StyledBox addText"> 
                        <div class="Boxed" @click="changeMenuBar">
                          <i class="fa-solid fa-plus"></i>
                        </div>
                        <!-- <div>
                          <i class="fa-regular fa-image py-2"></i>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col">
                      <textarea class="form-control" v-model="textChange" id="" rows="3" @keyup="changeText"></textarea>
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col col-sm-4 button-center">
                      <select id="" class="form-select mouse" v-model="fontSize" @change="changeText" >
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="14">14</option>
                      <option value="16">16</option>
                      <option value="18">18</option>
                      <option value="20">20</option>
                      <option value="22">22</option>
                      <option value="24">24</option>
                      <option value="26">26</option>
                      <option value="28">28</option>
                      <option value="30">30</option>
                      <option value="32">32</option>
                      <option value="34">34</option>
                      <option value="36">36</option>
                      <option value="38">38</option>
                      <option value="40">40</option>
                      <option value="42">42</option>
                      <option value="44">44</option>
                      <option value="46">46</option>
                      <option value="48">48</option>
                      <option value="50">50</option>
                      <option value="52">52</option>
                      <option value="54">54</option>
                      <option value="56">56</option>
                      <option value="58">58</option>
                      <option value="60">60</option>
                      <option value="62">62</option>
                      <option value="64">64</option>
                      <option value="66">66</option>
                      <option value="68">68</option>
                      <option value="70">70</option>
                      <option value="72">72</option>
                      <option value="74">74</option>
                      <option value="76">76</option>
                      <option value="78">78</option>
                      <option value="80">80</option>
                      <option value="82">82</option>
                      <option value="84">84</option>
                      <option value="86">86</option>
                      <option value="88">88</option>
                      <option value="90">90</option>
                      <option value="92">92</option>
                      <option value="94">94</option>
                      <option value="96">96</option>
                      <option value="98">98</option>
                      <option value="100">100</option>
                      </select>
                    </div>
                    <div class="col col-sm-2 button-center text-center button-h p-2">
                      <button class="btn button_Style" @click="changeText({bold:true})" ><i class="fa-solid fa-bold"></i></button>
                    </div>
                    <div class="col col-sm-2 button-center button-h p-2">
                      <button class="btn button_Style" @click="changeText({italic:true})" ><i class="fa-solid fa-italic"></i></button>
                    </div>
                    <!-- <div class="col col-sm-2 button-center button-h p-0">
                      <button class="btn button_Style" @click="addText"><i class="fa-solid fa-text-width"></i></button>
                    </div> -->
                  </div>
                </div>
              </div>
              <hr>
              <div class="row pt-">
                <div class="col">
                  <h6 class="font-color">Fill</h6>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col col-sm-3">
                  <color-picker v-model:pureColor="color"/>
                </div>
                <div class="col col-sm-3">
                  <div>color</div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col col-sm-8">
                  <label for="customRange2" class="form-label">Letter Spacing</label>
                  <input type="range" class="form-range" v-model="numberSpace" @change="changeText" min="0" max="10" id="letterSpace">
                </div>
                <div class="col col-sm-4 button-end">
                  <input type="number" v-model="numberSpace" @change="changeText" class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="col col-sm-8">
                  <label for="l_Height" class="form-label">Line Height</label>
                  <input type="range" class="form-range" v-model="lineHeight" @change="changeText" min="0" max="10" id="l_Height">
                </div>
                <div class="col col-sm-4 button-end">
                  <input type="number" v-model="lineHeight" @change="changeText" class="form-control">
                </div>
              </div>
              <hr>
              <div class="row pt-2">
                <div class="col">
                  <div class="accordion accordion-flush" id="accordionFlushBorder">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingBorder">
                        <button class="accordion-button collapsed p-2 button-Border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseBorder" aria-expanded="false" aria-controls="flush-collapseBorder">
                          Border
                        </button>
                      </h2>
                      <div id="flush-collapseBorder" class="accordion-collapse collapse" aria-labelledby="flush-headingBorder" data-bs-parent="#accordionFlushBorder">
                        <div class="accordion-body">
                          <div class="row pt-2">
                            <div class="col col-sm-3 button-center" @click="changeText">
                              <color-picker v-model:pureColor="border_Color"/>
                            </div>
                            <div class="col col-sm-4">
                              <input type="number" class="form-control" @click="changeText" v-model="borderWidth">
                            </div>
                            <div class="col col-sm-5">
                              <select name="" id="" class="form-select" @click="changeText" v-model="borderStyle" >
                                <option value="normal">Normal</option>
                                <option value="dotted">Dotted</option>
                                <option value="dashed">Dashed</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col" style="position:relative;" >
                  <img src="/assets/images/Vector.png" class="logo_Style" alt="">
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col">
                  <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button collapsed p-2 button-Border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Shadow
                        </button>
                      </h2>
                      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col col-sm-3 button-center">
                              <color-picker @click="changeText" v-model:pureColor="boxShadowColor"/>
                            </div>
                            <div class="col col-sm-3">
                              <input type="number" @click="changeText" class="form-control" v-model="boxShadowX" placeholder="X">
                            </div>
                            <div class="col col-sm-3">
                              <input type="number" @click="changeText" class="form-control" v-model="boxShadowY" placeholder="Y">
                            </div>
                            <div class="col col-sm-3 pe-0">
                              <input type="number" @click="changeText" class="form-control" v-model="boxShadowBlur" placeholder="Blur">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col">
                  <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button collapsed p-2 button-Border" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseAlign" aria-expanded="false" aria-controls="flush-collapseAlign">
                          Align
                        </button>
                      </h2>
                      <div id="flush-collapseAlign" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col col-sm-3 button-center text-center button-h">
                              <button class="btn button_Style" @click="changeText( {textEnd:true} )"><i class="fa-solid fa-align-left"></i></button>
                            </div>
                            <div class="col col-sm-3 button-center text-center button-h">
                              <button class="btn button_Style" @click="changeText( {textCenter:true} )"><i class="fa-solid fa-align-center"></i></button>
                            </div>
                            <div class="col col-sm-3 button-center text-center button-h">
                              <button class="btn button_Style" @click="changeText( {textStart:true} )"><i class="fa-solid fa-align-right"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="currentTagName == 'add'">
              <H1>dfkjsdfjslkdf</H1>
            </div> -->
          </div>
        </div>
      </div>
      <div v-else class="col-12 col-sm-4 col-xxl-3">
        <div class="card">
          <div class="card-body">
            <div  v-if="currentTagName">
              <div class="row pt-3">
                <div class="col col-sm-3 pe-0 text-center button-h">
                  <button :class="(rotation)? 'btn button_Style button-active' : 'btn button_Style' " id="btnRotate" @click="rotatex">
                    <i class="fa-solid fa-arrows-rotate"></i>
                  </button>
                </div>
                <div class="col col-sm-2 text-start p-0 button-h">
                  <button :class="(dragButton)? 'btn button_Style button-active':'btn button_Style' " @click="generateDrag">
                    <i class="fa-solid fa-maximize"></i>
                  </button>
                </div>
                <div class="col col-sm-2 text-start p-0 button-h">
                  <button :class="(is_Resize)? 'btn button_Style button-active':'btn button_Style' " @click="getResize">
                    <i class="fa-solid fa-minimize"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h6 class="card-title font-color pt-3">Text</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-9 col-sm-9">
                  <input type="text" class="form-control" v-model="valueText">
                </div>
                <div class="col-3 col-sm-3 text-center button-center">
                  <button class="btn btn-sm btn-generate" @click="insertTEXT">ADD</button>
                </div>
              </div>
              <div class="row pt-4">
                <div class="col-12 col-sm-12 col-xxl-12">
                  <button class="btn btn-sm btn-generate" @click="addImage">add Image</button>
                  <span class="mx-3" @click="removeImage"><i class="fa-solid fa-trash-can"></i></span>
                </div>
                <div class="col-12 col-sm-12 col-xxl-12 pt-2">
                  <div style="position: relative;">                        
                    <div class="form-text insertIamge" ><i class="fa-solid fa-cloud-arrow-up"></i> Drag a image here or click to choose a file.</div>
                    <DropZone class="addColor" @addedFile="onFileAdd" 
                      message="dsfjksdlfs"                               
                      :maxFiles="Number(1)"
                      :maxFileSize="10000000"
                      url="http://localhost:5000/item"
                      :uploadOnDrop="false"
                      :multipleUpload="true"
                      :parallelUpload="3"/> 
                  </div>                       
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col d-flex">
                      <div class="StyledBox addText"> 
                        <div class="Boxed" @click="changeMenuBar">
                          <i class="fa-solid fa-plus"></i>
                        </div>
                        <div class="pt-2" @click="changeMenuBars">
                          <i class="fa-solid fa-circle-arrow-left"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 col-xxl-9 pt-3">
        <div id="svgEditor" style="overflow: hidden;">
          <!-- <svg width="650" height="650" id="svgCanvas">
            <text x="50" y="50" font-family="Verdana"  id="textElement" class="draggable">Sample Text</text>
            <image href="https://via.placeholder.com/150" x="100" y="100" width="150" height="150" id="imageElement" class="draggable resizable" ></image>
          </svg> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters} from 'vuex';
import { DropZone } from 'dropzone-vue';
export default {
  name: 'editor',
  data(){
    return {
      imgUrl: this.$route.query.img,
      color: "",
      numberSpace: 0,
      lineHeight: 0,
      textChange: "",
      border_Color: "",
      currentTagName: "",
      currentElement: null,
      fontSize: "",
      dragButton: false,
      mouseClicked: false,
      is_Resize: false,
      rotation: false,
      valueText: "",
      storage: [],
      urlImage: process.env.VUE_APP_BASE_URL+"/media/suggested/f8f8548b7f99d2f48d54a41f01d372bca894370c2179b2b8061ee66d.svg",
      addImg: false,
      menuBar: 1,
      picture: "",
      historyIndex: 0,
      borderWidth: 0,
      borderStyle: "",
      boxShadowColor: '',
      boxShadowX: '',
      boxShadowY: '',
      boxShadowBlur: '',
    }
  },
  computed:{
    ...mapGetters(["BASE_URL"]),
  },
  components:{
    DropZone,
  },
  methods:{
    getSVGData(){
      fetch(this.urlImage)
      .then(response => response.text())
      .then(data => {
        var svgContainer = document.getElementById('svgEditor');
        svgContainer.innerHTML = data;
        this.editor();
      });
    },
    editor(){
      // document.addEventListener('DOMContentLoaded', function () {
      var svg = document.getElementById('svgEditor').querySelector('svg');
      // var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      let selectedElement = null;
      let offset = { x: 0, y: 0 };
      let initialSize = { width: 0, height: 0 };
      let initialMousePos = { x: 0, y: 0 };
      let rotateDeg = 0;
      // let resizing = false;
      const prepearText = () => {
        this.textChange = selectedElement.textContent;
        var computedStyle = window.getComputedStyle(selectedElement);
        this.fontSize = parseInt(computedStyle.fontSize);
      }

      const startDrag = (event) => {
        this.mouseClicked = true;
        if(event.target != selectedElement){
          this.dragButton = false;
        }
        selectedElement = event.target;
        this.currentElement = selectedElement;
        this.currentTagName = selectedElement.tagName;
        if(selectedElement.tagName == 'text'|| selectedElement.tagName == 'tspan'){
          prepearText();
        }
        
        if(this.dragButton){  
          offset.x = event.clientX - selectedElement.getBoundingClientRect().x;
          offset.y = event.clientY - selectedElement.getBoundingClientRect().y;
        }
          // offset.x = event.clientX - selectedElement.getBoundingClientRect().left;
          // offset.y = event.clientY - selectedElement.getBoundingClientRect().top;
          // console.log(offset);
          // pos3 = event.clientX;
          // pos4 = event.clientY;
        if(this.is_Resize){
          initialSize.width = parseFloat(selectedElement.getAttribute('width'));
          initialSize.height = parseFloat(selectedElement.getAttribute('height'));
          initialMousePos.x = event.clientX;
          initialMousePos.y = event.clientY;
        }
        // if (event.target.classList.contains('draggable')) {
          

        //   if (event.target.classList.contains('resizable')) {
        //     // resizing = true;
        //     initialSize.width = parseFloat(selectedElement.getAttribute('width'));
        //     initialSize.height = parseFloat(selectedElement.getAttribute('height'));
        //     initialMousePos.x = event.clientX;
        //     initialMousePos.y = event.clientY;
        //   }
      }
      
      // }
      // const drag = (event) => {
      //   if (selectedElement) {
      //     if (resizing) {
      //       var dx = event.clientX - initialMousePos.x;
      //       var dy = event.clientY - initialMousePos.y;
      //       var newWidth = initialSize.width + dx;
      //       var aspectRatio = initialSize.width / initialSize.height;
      //       var newHeight = newWidth / aspectRatio;

      //       selectedElement.setAttribute('width', newWidth);
      //       selectedElement.setAttribute('height', newHeight);
      //     } else {
      //       if(this.dragButton && this.mouseClicked){
      //         pos1 = event.clientX - pos3;
      //         pos2 = event.clientY - pos4;
      //         pos3 = event.clientX;
      //         pos4 = event.clientY;
      //         console.log('here');
      //         console.log(pos3);
      //         console.log(selectedElement.getBoundingClientRect().top);
      //         requestAnimationFrame(() => {
      //           selectedElement.setAttribute('x', pos1);
      //           selectedElement.setAttribute('y', pos2);
      //         });
      //         // offset.x = event.clientX - selectedElement.getBoundingClientRect().left;
      //         // offset.y = event.clientY - selectedElement.getBoundingClientRect().top;
      //         // selectedElement.setAttribute('style', 'position: absolute; top: ' + (selectedElement.getBoundingClientRect().top - pos2) + 'px; left: ' + (selectedElement.getBoundingClientRect().left - pos1) +'px;');
      //         // set the element's new position:
      //         // selectedElement.style.top = (selectedElement.offsetTop - pos2) + "px";
      //         // selectedElement.style.left = (selectedElement.offsetLeft - pos1) + "px";
      //       }
      //     }
      //   }
      // }
      const drag = (event) => {
        if (selectedElement) {
          // start resize 
          if (this.is_Resize && this.mouseClicked){
            selectedElement.classList.add("resizable");
            var dx = event.clientX - initialMousePos.x;
            var dy = event.clientY - initialMousePos.y;
            var newWidth = initialSize.width + dx;
            var aspectRatio = initialSize.width / initialSize.height;
            var newHeight = newWidth / aspectRatio

            
            let oldWidth = selectedElement.getAttribute('width');
            let oldHeight = selectedElement.getAttribute('height');
            
            if(!oldWidth || !oldHeight){
              oldWidth = '';
              oldHeight = '';
            }
            selectedElement.setAttribute('width', newWidth);
            selectedElement.setAttribute('height', newHeight);
            let historyResize = {el: selectedElement, attrName: ['width','height'], originValue: [oldWidth,oldHeight], changeValue: [newWidth,newHeight] };
            this.storage.push(historyResize);
            this.historyIndex = this.storage.length - 1;
            //console.log(this.storage);
            

            // start  rotation 
          } else if(this.rotation && this.mouseClicked){
            if(selectedElement.tagName == 'tspan'){
              selectedElement = selectedElement.parentElement;
              // selectedElement.style.cursor = "crosshair";
            }
            let rotext = selectedElement.getAttribute('transform'); 
            rotateDeg = rotateDeg + 1;
            let oldRotext = '';
            if(rotext){
              let get_Rotate = rotext.split(")");
              let rotateion = `${get_Rotate[0]}),rotate(${rotateDeg})`;
              selectedElement.setAttribute('transform',rotateion);
              // selectedElement.setAttribute('text-anchor', 'middle');
              selectedElement.setAttribute('dominant-baseline', 'middle');
              selectedElement.classList.add("rotatingT");
              oldRotext = rotext;
              // console.log(rotext);
            }else {
              oldRotext = selectedElement.getAttribute('style') ?? '';
              selectedElement.style.transform =`rotate(${rotateDeg}deg)`;
              selectedElement.classList.add("rotating");
              // console.log(rotateDeg);
              let changedValue = `transform: rotate(${rotateDeg}deg);`;
              let newHisoryR = {el: selectedElement, attrName: "style" ,originValue: oldRotext, changeValue: changedValue};
                this.storage.push(newHisoryR);
                this.historyIndex = this.storage.length - 1;
              // console.log(this.storage);
            }
            if(!oldRotext){
              oldRotext = '';
            }
          } else {
            // start drag 
            if(this.dragButton && this.mouseClicked){
              // const container = document.getElementById('svgEditor');
              // const containerRect = container.getBoundingClientRect();
              if(selectedElement.tagName == 'tspan'){
                selectedElement = selectedElement.parentElement;
                //let get_Matrix = selectedElement.getAttribute('transform');
                //let matrix = get_Matrix.split(',');
                // console.log(matrix);
                // console.log(matrix[4]);
                // console.log(matrix[5]);
                // console.log(text_X);
                // console.log(text_Y);
              }              
              var x = event.clientX - offset.x;
              var y = event.clientY - offset.y;

              // let newX = event.clientX - containerRect.left - offset.x;
              // let newY = event.clientY - containerRect.top - offset.y;

              // // Ensure the element stays within the container
              // newX = Math.max(0, Math.min(newX, containerRect.width - selectedElement.getBBox().width));
              // newY = Math.max(0, Math.min(newY, containerRect.height - selectedElement.getBBox().height));
      
              requestAnimationFrame(() => {
                selectedElement.setAttribute('x', x);
                selectedElement.setAttribute('y', y);
              });

              // const container = document.getElementById('svgEditor');
              // const containerRect = container.getBoundingClientRect();
              // let newX = event.clientX - containerRect.left - offset.x;
              // let newY = event.clientY - containerRect.top - offset.y;

              // // Ensure the element stays within the container
              // newX = Math.max(0, Math.min(newX, containerRect.width - selectedElement.width.baseVal.value));
              // newY = Math.max(0, Math.min(newY, containerRect.height - selectedElement.height.baseVal.value));

              // requestAnimationFrame(() => {
              //   selectedElement.setAttribute('x', newX);
              //   selectedElement.setAttribute('y', newY);
              // });
            }
          }
          var svg = document.getElementById('svgEditor').querySelector('svg'),
          zoomIN = document.getElementById('zoomPlus');
          let zoomVaule = 1;
          zoomIN.addEventListener("click", function(e) {
            svg.style.transform = `scale(${(zoomVaule += 0.06)})`;
          });

          let zoomOut = document.getElementById('zoomMinus');
          zoomOut.addEventListener("click", function(e) {
            svg.style.transform = `scale(${(zoomVaule -= 0.06)})`;
          });
        }
      }
      
      const endDrag = (event) => {
        // selectedElement = null;
        this.mouseClicked = false;
        // resizing = false;
      }
      svg.addEventListener('mousedown', startDrag);
      svg.addEventListener('mousemove', drag);
      svg.addEventListener('mouseup', endDrag);
      svg.addEventListener('mouseleave', endDrag);
    },
    // Function to change text
    changeText({ fontFamily=false,bold=false,italic=false,textCenter=false,textEnd=false,textStart=false} = {}) {
      if (this.currentElement && this.currentElement.tagName == 'text' || this.currentElement.tagName == 'tspan' || this.currentElement.tagName == 'path') {
        if(this.currentElement.tagName == 'tspan'){
          this.currentElement = this.currentElement.parentElement;
        }
        this.currentElement.textContent = this.textChange;
        var computedStyle = window.getComputedStyle(this.currentElement);
        var style = "";
        if (fontFamily) this.currentElement.setAttribute('font-family', fontFamily);
        if (this.fontSize !="") style += `font-size: ${this.fontSize}px;`;
        if (this.color !="") style += "fill:" +this.color + ';';

        if(bold){
          if(computedStyle.fontWeight == 700 ){
            style += "font-weight:400;";
          }else{
            style += "font-weight:700;";
          }
        }else{
          if(computedStyle.fontWeight == 700 ){
            style += "font-weight:700;";
          }
        }

        if(italic){
          if(computedStyle.fontStyle == "normal"){
            style += "font-style: italic;";
          }
        }else{
          if(computedStyle.fontStyle == "italic"){
            style += "font-style: italic;";
          }
        }

        if(textStart){
          if(computedStyle.textAnchor !== ""){
            style += "text-anchor: start;";
          }
        }

        if(textCenter){
          if(computedStyle.textAnchor !== ""){
            style += "text-anchor: middle;";
          }
        }
        if(textEnd){
          if(computedStyle.textAnchor !== ""){
            style += "text-anchor: end;";
            
          }
        }
        if(this.numberSpace !="") style += `letter-spacing: ${this.numberSpace}px;`;

        if(this.lineHeight !="") style += `line-height: ${this.lineHeight}px;`;

        if(this.border_Color !="") style += `stroke: ${this.border_Color};`;

        if(this.borderWidth !="") style += `stroke-width: ${this.borderWidth};`;

        if(this.borderStyle !="") style += `border-style: ${this.borderStyle};`;

        if(this.boxShadowColor !="") style += `filter:drop-shadow ${this.boxShadowColor}`;
        if(this.boxShadowX !="") style += `filter:drop-shadow ${this.boxShadowX}`;
        if(this.boxShadowY !="") style += `filter:drop-shadow ${this.boxShadowY}`;
        if(this.boxShadowBlur !="") style += `filter:drop-shadow ${this.boxShadowBlur};`;

        if(style != ""){
          let oldStyle = this.currentElement.getAttribute('style');
          if(!oldStyle){
            oldStyle = ''
          }
          this.currentElement.setAttribute('style', style);
          let newHisory = {el: this.currentElement, attrName: "style" ,originValue: oldStyle, changeValue: style};
          this.storage.push(newHisory);
          this.historyIndex = this.storage.length - 1;
          // console.log(this.storage);
          
        }
      };
    },
    insertTEXT() {
      let textElement = document.createElementNS("http://www.w3.org/2000/svg",'text');
      textElement.setAttribute('x', 250);
      textElement.setAttribute('y', 250);
      textElement.setAttribute('font-size', 50+'px');
      textElement.setAttribute('fill', 'black');
      textElement.textContent = this.valueText;
      var svg = document.getElementById('svgEditor').querySelector('svg');
      svg.appendChild(textElement);
      // console.log(textElement);
    },

    addImage(){
        // Create a new image element
      const image = document.createElementNS("http://www.w3.org/2000/svg", "image");
      // Set the attributes for the image element
      image.setAttribute("x", "250");
      image.setAttribute("y", "150");
      image.setAttribute("width", "100");
      image.setAttribute("height", "100");
      image.setAttribute("href",this.picture); // Image source URL
      // Append the image element to the SVG
      
      // console.log(this.picture);
      var svg = document.getElementById('svgEditor').querySelector('svg');
      svg.appendChild(image);
      document.getElementsByClassName("gg-close")[0].click();
    },

    removeImage(){
      var svg = document.getElementById('svgEditor').querySelector('svg');
      const image = svg.querySelector('image');
      // console.log(image);
      if(this.currentElement){
        svg.removeChild(this.currentElement);
      }
    },

    generateDrag(){
      if(this.dragButton){
        this.dragButton = false;
      }else{
        this.dragButton = true;
      }
    },

    getResize(){
      if(this.is_Resize){
        this.is_Resize = false;
      }else{
        this.is_Resize = true;
      }
    },

    // zoomIN(e){
    //   var svg = document.getElementById('svgEditor').querySelector('svg');
    //   if(e.deltaY > 0){
    //     svg.style.transform = `scale(${(zoomVaule += 0.06)})`;
    //   } 
    // },

    rotatex() {
      if(this.rotation){
        this.rotation = false;
      }else{
        this.rotation = true;
      }
    },

    changeMenuBar() {
      // this.currentTagName = "add";
      if(this.menuBar == 1){
        this.menuBar = 2;
      }
    },
    changeMenuBars() {
      // this.currentTagName = "add";
      if(this.menuBar == 2){
        this.menuBar = 1;
      }
    },

    resetButton(){
      this.getSVGData();
    },

    convertSVG(){
      const svg = document.getElementById('svgEditor').querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);

      // Create a Blob from the SVG data
      const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(blob);

      // Create a new image element
      const img = new Image();
      img.onload = function () {
          // Create a canvas and draw the SVG image on it
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          // Convert canvas to PNG data URL
          const pngDataUrl = canvas.toDataURL('image/png');

          // Create a link element and trigger download
          const link = document.createElement('a');
          link.href = pngDataUrl;
          link.download = 'image.png';
          document.body.appendChild(link); // Append link to the body
          link.click();
          // document.body.removeChild(link); // Remove the link from the body

          // Clean up
          // URL.revokeObjectURL(url);
        };
      img.onerror = function () {
          console.error('Image failed to load');
      };
      img.src = url;
    },

    onFileAdd(e){
      if (e.file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        // document.getElementById('output').textContent = base64String;
        // console.log(base64String); // Output to console
        this.picture = base64String;
      };
      reader.onerror = function(error) {
        console.error('Error: ', error);
      };
      reader.readAsDataURL(e.file); // Read the file as a data URL (Base64)
      }
    },

    redo(){
      if(this.storage.length >=1 && this.historyIndex >= 0){
        let currentHistroy = this.storage[this.historyIndex];
        if(!currentHistroy){
          return;
        }

        if(Array.isArray(currentHistroy.attrName)){
          for(let i=0; currentHistroy.attrName.length; i++){
            currentHistroy.el.setAttribute(currentHistroy.attrName[i] ,currentHistroy.changeValue[i]);
          }
        }else{
          currentHistroy.el.setAttribute(currentHistroy.attrName ,currentHistroy.changeValue);
        }
        this.historyIndex += 1;
      }
    },

    undo(){
      if(this.storage.length >= 1 && this.historyIndex >= 0 ){
        let currentHistroy = this.storage[this.historyIndex];
        if(!currentHistroy){
          return;
        }

        if(Array.isArray(currentHistroy.attrName)){
          for(let i=0; i<currentHistroy.attrName.length; i++){
            currentHistroy.el.setAttribute(currentHistroy.attrName[i] ,currentHistroy.originValue[i]);
          }
        }else{
          currentHistroy.el.setAttribute(currentHistroy.attrName ,currentHistroy.originValue);
        }
        this.historyIndex -= 1;
      }
    },

    getSVGurl(url){
      let urlArray = url.split('/');
      let index = urlArray.indexOf('images');
      if(index != -1){
        urlArray.splice(index, 1);
        let imageNameArray = urlArray[urlArray.length - 1].split('.');
        imageNameArray[imageNameArray.length - 1] = 'svg';
        urlArray[urlArray.length - 1] = imageNameArray.join('.');
      }
      return urlArray.join('/');
    },
    
  },

  created(){
    if(this.imgUrl){
      this.urlImage = this.this.getSVGurl(this.imgUrl);
      this.getSVGData();
    }
    // console.log(this.$router.currentRoute.value.name);
    
  },

  watch:{
    color(newVal, oldVal){
      if(newVal){
        this.changeText();
      }
    }
  }
};
</script>
<style scoped> 
  #svgEditor {
    width: 100%;
    /* height: 600px; */
    border: 1px solid #cecece;
  }
  .draggable {
    cursor: move;
    
  }
  /* .resizable {
    cursor: nwse-resize;
  } */
</style>